import * as React from "react";

import { Theme, createTheme, lighten, darken } from "@mui/material/styles";

import { zeffyTheme } from "../theme/theme";

import { palette } from "@/theme/palette";

export const createFrontendTheme = (color: string | null, transparent = false) =>
  createTheme({
    ...zeffyTheme,
    palette: {
      ...palette,
      background: {
        default: transparent ? "transparent" : palette.text.reverse.intense,
      },
      component: "form",
      border: {
        ...palette.border,
        brand: color
          ? {
              intense: color,
              moderate: lighten(color, 0.4),
              quiet: lighten(color, 0.8),
            }
          : palette.border.brand,
      },
      surface: {
        ...palette.surface,
        brand: color
          ? {
              intense: color,
              moderate: lighten(color, 0.75),
              quiet: lighten(color, 0.9),
            }
          : palette.surface.brand,
      },
      text: {
        ...palette.text,
        primary: palette.neutral[20],
        secondary: palette.neutral[50],
        brand: color
          ? {
              intense: darken(color, 0.2),
              moderate: color,
              quiet: lighten(color, 0.2),
            }
          : palette.text.brand,
      },
      primary: color
        ? {
            darkest: darken(color, 0.5),
            dark: darken(color, 0.3),
            main: color,
            light: lighten(color, 0.7),
            lighter: lighten(color, 0.9),
            lightest: lighten(color, 0.95),
            contrastText: palette.primary.contrastText,
          }
        : { ...palette.primary },
      secondary: color
        ? {
            darkest: darken(color, 0.7),
            dark: darken(color, 0.4),
            main: lighten(color, 0.5),
            light: lighten(color, 0.7),
            lighter: lighten(color, 0.8),
            lightest: lighten(color, 0.9),
            contrastText: palette.secondary.contrastText,
          }
        : { ...palette.secondary },
    },
  });

export const useTheme = (color: string | null, transparent = false): Theme =>
  React.useMemo<Theme>(() => createFrontendTheme(color, transparent), [color, transparent]);
