import { FC, useContext } from "react";

import { FormType } from "@simplyk/common";
import { UseFormReturn } from "react-hook-form";

import { ThankYouLoader } from "../../../components/Animations/ThankYouLoader/ThankYouLoader";
import { useTicketingKeyValues } from "../../../components/TicketingDrawers/useTicketingKeyValues";
import { useTicketDrawer } from "../../../components/TicketSelection/useTicketDrawer";
import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { FrontendTicketingContext } from "../../../contexts/FrontendTicketingContext";
import { useFormV2Context } from "../context/FormV2Context";

import { TicketingPaymentInput } from "@/types/ticketing";

export const TicketingThankYouLoader: FC = () => {
  const { form } = useFormV2Context();
  const ticketingForm = form as UseFormReturn<TicketingPaymentInput>;
  const { organization, generateETicket } = useContext(FrontendFormContext);
  const { hasADate } = useContext(FrontendTicketingContext);
  const { displayGenerateReceipt: generateTaxReceipt } = useTicketDrawer({
    form: ticketingForm,
  });
  const { amount: totalAmount, hasNoETickets } = useTicketingKeyValues({
    control: ticketingForm.control,
    setValue: ticketingForm.setValue,
  });

  return (
    <ThankYouLoader
      organizationName={organization.name}
      formType={FormType.Ticketing}
      generateTaxReceipt={generateTaxReceipt}
      generateTicket={generateETicket && !hasNoETickets}
      isFree={totalAmount === 0}
      hasADate={hasADate}
    />
  );
};

export const DonationFormThankYouLoader: FC = () => {
  const { formObject, displayGenerateReceipt } = useFormV2Context(FormType.DonationForm);
  const { organization } = formObject;

  return (
    <ThankYouLoader
      organizationName={organization!.name}
      formType={FormType.DonationForm}
      generateTaxReceipt={displayGenerateReceipt}
      generateTicket={false}
      isFree={false}
      hasADate={false}
    />
  );
};
