import { FC } from "react";

import { Stack, useTheme } from "@mui/material";
import { AvailablePaymentCurrency, formatNarrowCurrencyAmount, getDollarValue } from "@simplyk/common";

import { NextLink } from "@/components/NextLink/NextLink";
import { useLocaleContext } from "@/contexts/LocaleContext";
import { Avatar } from "@/design-system/Avatar";
import { Icon } from "@/design-system/Icon";
import { Typography } from "@/design-system/Typography";
import { useTranslate } from "@/hooks/useTranslate";
import { User } from "@/icons/filled";
import { ArrowDiagonalRight } from "@/icons/outlined";

export type FundraiserProps = {
  href: string;
  imageSrc: string | null;
  title: string;
  subtitle: string;
  totalRaised?: number;
  currency: AvailablePaymentCurrency;
  showFallbackImage?: boolean;
  "data-test"?: string;
};

export const Fundraiser: FC<FundraiserProps> = ({
  href,
  imageSrc,
  title,
  subtitle,
  totalRaised,
  currency,
  showFallbackImage = false,
  "data-test": dataTest,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const { isoLocale } = useLocaleContext();
  const hasTotalRaised = totalRaised !== undefined && !!currency;

  const formattedTotalRaised = hasTotalRaised
    ? formatNarrowCurrencyAmount(getDollarValue(totalRaised), {
        locale: isoLocale,
        currency,
        withCents: totalRaised % 100 !== 0,
      })
    : null;

  return (
    <NextLink href={href} data-test={dataTest}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1}
        sx={{
          padding: theme.spacing(0.5, 1),
          cursor: "pointer",
          borderRadius: theme.radius(1),
          transition: theme.transitions.create(["background-color"], {
            duration: theme.transitions.duration.shorter,
            easing: theme.transitions.easing.easeInOut,
          }),
          [`&:hover`]: {
            backgroundColor: theme.palette.surface.form.supershy,
            [`& .ZeffyFundraiser-icon`]: {
              opacity: 1,
            },
          },
        }}
      >
        <Stack flexGrow={1} minWidth={0} direction="row" gap={1.5} alignItems="center" sx={{ borderRadius: 1 }}>
          {(imageSrc || showFallbackImage) && (
            <Avatar
              src={imageSrc ?? undefined}
              size="small"
              color={theme.palette.surface.form.quiet}
              sx={{ flexShrink: 0, borderRadius: 0.375 }}
            >
              <Icon>
                <User fill={theme.palette.surface.form.intense} />
              </Icon>
            </Avatar>
          )}
          <Stack flexGrow={1} minWidth={0}>
            <Typography
              variant="body2"
              vibe="text-form-moderate"
              sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {title}
            </Typography>
            <Stack
              direction="row"
              gap={0.5}
              alignItems="center"
              minWidth={0}
              divider={
                <Typography variant="caption" vibe="text-form-quiet">
                  ·
                </Typography>
              }
            >
              <Typography
                flex="0 1 auto"
                variant="caption"
                vibe="text-form-quiet"
                sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {subtitle}
              </Typography>
              {totalRaised !== undefined && (
                <Typography flexShrink={0} variant="caption" vibe="text-form-quiet" sx={{ whiteSpace: "nowrap" }}>
                  {t("donationForm", "peerToPeer.totalRaised", {
                    totalRaised: formattedTotalRaised,
                  })}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
        <Icon
          className="ZeffyFundraiser-icon"
          vibe="text-form-moderate"
          sx={{
            flexShrink: 0,
            opacity: 0,
            transition: theme.transitions.create(["opacity"], {
              duration: theme.transitions.duration.shorter,
              easing: theme.transitions.easing.sharp,
            }),
          }}
        >
          <ArrowDiagonalRight />
        </Icon>
      </Stack>
    </NextLink>
  );
};
