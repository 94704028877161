import { Box, Stack } from "@mui/material";

import { Typography } from "../../../../../../components/design-system/Typography";
import { useTranslate } from "../../../../../../hooks/useTranslate";

import { Tile } from "@/features/FormV2/sharedComponents/Tile/Tile";
interface StatelessHostedByProps {
  slots: {
    generalFundraiser: React.ReactNode;
    teamFundraiser?: React.ReactNode;
  };
}

export const StatelessHostedBy = ({ slots }: StatelessHostedByProps) => {
  const { t } = useTranslate();
  const showHostedByTeam = !!slots.teamFundraiser;

  return (
    <Tile>
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1" vibe="text-form-quiet">
          {t("donationForm", "hostedBy.title")}
        </Typography>
        {showHostedByTeam ? (
          <Stack direction={{ md: "row" }} gap={2} display="flex" justifyContent="stretch">
            <Box flex={1}>
              <Typography variant="subtitle1" vibe="text-form-moderate" marginBottom={1}>
                {t("donationForm", "hostedBy.mainCampaign")}
              </Typography>
              {slots.generalFundraiser}
            </Box>
            <Box flex={1}>
              <Typography variant="subtitle1" vibe="text-form-moderate" marginBottom={1}>
                {t("donationForm", "hostedBy.team")}
              </Typography>
              {slots.teamFundraiser}
            </Box>
          </Stack>
        ) : (
          slots.generalFundraiser
        )}
      </Stack>
    </Tile>
  );
};
