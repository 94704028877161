import { FC, PropsWithChildren } from "react";

import { FormType } from "@simplyk/common";

import { FormV2StepKey } from "../types/FormV2StepKey";

import { DonationFormV2Provider } from "./DonationFormV2Provider";
import { TicketingV2Provider } from "./TicketingV2Provider";

import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export type FormV2ProviderProps = {
  formObject: TicketingOutput | DonationFormOutput;
  currentStep: FormV2StepKey;
  setCurrentStep: (step: FormV2StepKey) => void;
  type: FormType.Ticketing | FormType.DonationForm;
};

export const FormV2Provider: FC<PropsWithChildren<FormV2ProviderProps>> = ({
  children,
  type,
  formObject,
  currentStep,
  setCurrentStep,
}) => {
  if (type === FormType.Ticketing) {
    return (
      <TicketingV2Provider
        formObject={formObject as TicketingOutput}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        type={type}
      >
        {children}
      </TicketingV2Provider>
    );
  }

  return (
    <DonationFormV2Provider
      formObject={formObject as DonationFormOutput}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      type={type}
    >
      {children}
    </DonationFormV2Provider>
  );
};
