import * as React from "react";

import { TicketingFormCategory } from "@simplyk/common";
import { UseFormReturn, useWatch } from "react-hook-form";

import { FrontendTicketingContext } from "../../contexts/FrontendTicketingContext";
import { getDisplayGenerateReceipt, getEligibleAmountTotal } from "../../helpers/rates";
import { TicketingFormData } from "../TicketingDrawers/type";

export const useTicketDrawer = ({ form }: { form: UseFormReturn<TicketingFormData> }) => {
  const { control } = form;
  const ticketsPurchased = useWatch({ control, name: "ticketsPurchased" });
  const extraDonation = useWatch({ control, name: "extraDonation" }) || 0;
  const { ticketing, validDiscount, hasDiscountCodes } = React.useContext(FrontendTicketingContext);

  const ticketingCanHaveExtraDonation = Boolean(ticketing.extraDonation);
  const ticketingHasExtraDonation = ticketingCanHaveExtraDonation && Boolean(extraDonation);

  const allTicketsSelectedNumber = React.useMemo(
    () =>
      ticketsPurchased?.reduce((prev, current) => {
        if (!current.purchasedNumber) {
          return prev;
        }
        if (current.attendeesByTicket) {
          return prev + current.attendeesByTicket * parseInt(current.purchasedNumber.toString());
        }
        return prev + parseInt(current.purchasedNumber.toString());
      }, 0) || 0,
    [ticketsPurchased]
  );

  const isMembershipV2 = ticketing.formCategory === TicketingFormCategory.MembershipV2;
  const eligibleAmountTotal = getEligibleAmountTotal(ticketsPurchased, ticketing, extraDonation, validDiscount);
  const displayGenerateReceipt = getDisplayGenerateReceipt(ticketsPurchased, ticketing, extraDonation);

  return {
    hasDiscountCodes,
    ticketingCanHaveExtraDonation,
    ticketingHasExtraDonation,
    allTicketsSelectedNumber,
    isMembershipV2,
    eligibleAmountTotal,
    displayGenerateReceipt,
    extraDonation,
  };
};
