import { forwardRef, useCallback, KeyboardEvent } from "react";

import { Icon } from "../Icon";
import { TextField } from "../TextField";

import { Close } from "@/icons/filled";
import { Search } from "@/icons/outlined";

interface SearchFieldProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
}

export const SearchField = forwardRef<HTMLInputElement, SearchFieldProps>(({ value, onChange, placeholder }, ref) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange?.(e.target.value);
  };

  const handleOnClear = useCallback(() => {
    onChange?.("");
  }, [onChange]);

  const handleOnKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        handleOnClear();
      }
    },
    [handleOnClear]
  );

  return (
    <TextField
      ref={ref}
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
      startAdornment={
        <Icon vibe="text-form-quiet">
          <Search />
        </Icon>
      }
      endAdornment={
        value && (
          <Icon onClick={handleOnClear} vibe="text-form-quiet">
            <Close />
          </Icon>
        )
      }
      onKeyDown={handleOnKeyDown}
    />
  );
});

SearchField.displayName = "SearchField";
