import { FC, useEffect } from "react";

import { Stack } from "@mui/material";
import { CampaignAction } from "@simplyk/common";

import { Button } from "../../../../../components/design-system/Button";
import { Typography } from "../../../../../components/design-system/Typography";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { FundraisingForm } from "../FundraisingForm";
import { FundraisingTypeOptions } from "../FundraisingTypeOptions";

import { useFundraiseDialog } from "./useFundraiseDialog";

import { InformationDialog, InformationDialogProps } from "@/design-system/Dialog/InformationDialog";
import { GeneralFundraiserProps } from "@/features/FormV2/Step1/PeerToPeer/GeneralFundraiser";
import { IndividualFundraiserProps } from "@/features/FormV2/Step1/PeerToPeer/IndividualFundraiser";
import { TeamFundraiserProps } from "@/features/FormV2/Step1/PeerToPeer/TeamFundraiser";

type CampaignType = { type: "general" | "team" | "individual" };
export type SearchedCampaign =
  | (GeneralFundraiserProps & CampaignType)
  | (TeamFundraiserProps & CampaignType)
  | (IndividualFundraiserProps & CampaignType);

export type FundraiseDialogProps = Pick<InformationDialogProps, "open" | "onClose"> & {
  campaignId: string;
  allowTeamCreation: boolean;
  allowFundraiserCreation: boolean;
  defaultTeamId?: string;
};

export const FundraiseDialog: FC<FundraiseDialogProps> = ({
  open,
  onClose,
  campaignId,
  allowTeamCreation,
  allowFundraiserCreation,
  defaultTeamId,
}) => {
  const { t } = useTranslate();

  const {
    campaignAction,
    form,
    onSubmit,
    toggleCampaignAction,
    loading,
    hasTeam,
    submitIsDisabled,
    organizationCountry,
    defaultTarget,
  } = useFundraiseDialog({
    open: true,
    toggle: () => {},
    campaignId,
    defaultAction: defaultTeamId ? CampaignAction.JoinTeam : CampaignAction.CreateTeam,
  });

  useEffect(() => {
    if (!open) {
      // Reset form data when dialog is closed
      form.reset();
    }
  }, [open, form]);

  const actionButtons = (
    <Stack
      direction={{ xs: "column", md: "row-reverse" }}
      justifyContent={{ md: "flex-start" }}
      spacing={1}
      sx={{ width: "100%", padding: 1 }}
    >
      <Button
        variant="filled"
        onClick={onSubmit}
        disabled={submitIsDisabled}
        isLoading={loading}
        data-test="create-campaign-button"
      >
        {campaignAction === "CreateTeam"
          ? t("donationForm", "fundraiseDialog.button.createTeam")
          : campaignAction === "JoinTeam"
            ? t("donationForm", "fundraiseDialog.button.joinTeam")
            : t("donationForm", "fundraiseDialog.button.createCampaign")}
      </Button>
      <Button variant="outlined" onClick={onClose}>
        {t("donationForm", "fundraiseDialog.button.cancel")}
      </Button>
    </Stack>
  );

  const title = (
    <Typography variant="h6" id="toto">
      {t("donationForm", "fundraiseDialog.title")}
    </Typography>
  );

  return (
    <InformationDialog title={title} open={open} onClose={onClose} noPadding actions={actionButtons}>
      <form noValidate>
        <Stack sx={{ paddingInline: 3, paddingBlock: 2 }} spacing={2}>
          {allowTeamCreation && (
            <FundraisingTypeOptions
              campaignAction={campaignAction}
              toggleCampaignAction={toggleCampaignAction}
              allowTeamCreation={Boolean(allowTeamCreation)}
              allowFundraiserCreation={Boolean(allowFundraiserCreation)}
              hasTeam={hasTeam}
              showCreateTeamFirst
            />
          )}
          <FundraisingForm
            errors={form.formState.errors}
            register={form.register}
            control={form.control}
            setValue={form.setValue}
            campaignAction={campaignAction}
            organizationCountry={organizationCountry}
            campaignId={campaignId}
            defaultTarget={defaultTarget}
            defaultTeamId={defaultTeamId}
          />
        </Stack>
      </form>
    </InformationDialog>
  );
};
