import { memo } from "react";

import MaterialAvatar from "@mui/material/Avatar";
import { styled, Theme } from "@mui/material/styles";

import { InternalClasses } from "../helpers";

import { AvatarBaseClasses } from "./types";

const PREFIX = "ZeffyAvatar";

export const zeffyAvatarClasses: InternalClasses<typeof PREFIX, AvatarBaseClasses> = {
  avatar: `${PREFIX}-avatar`,
  extraSmall: `${PREFIX}-extraSmall`,
  small: `${PREFIX}-small`,
  medium: `${PREFIX}-medium`,
  large: `${PREFIX}-large`,
};

export const StyledAvatar = memo(
  styled(MaterialAvatar)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyAvatarClasses.avatar}`]: {
      margin: 0,
      padding: 0,

      "&:hover": {
        textDecoration: "none",
      },
    },

    [`&.${zeffyAvatarClasses.extraSmall}`]: {
      height: theme.avatar.size.extraSmall,
      width: theme.avatar.size.extraSmall,
      ...theme.typography.caption,
    },

    [`&.${zeffyAvatarClasses.small}`]: {
      height: theme.avatar.size.small,
      width: theme.avatar.size.small,
      ...theme.typography.caption,
    },

    [`&.${zeffyAvatarClasses.medium}`]: {
      height: theme.avatar.size.medium,
      width: theme.avatar.size.medium,
      ...theme.typography.body2,
    },

    [`&.${zeffyAvatarClasses.large}`]: {
      height: theme.avatar.size.large,
      width: theme.avatar.size.large,
      ...theme.typography.body1,
    },
  }))
);
