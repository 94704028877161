/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useCallback } from "react";

import { Box, Stack, useTheme } from "@mui/material";

import { Typography } from "../../../../../components/design-system/Typography";
import { useLocaleContext } from "../../../../../contexts/LocaleContext";
import { isV2FrontendUrl, setV2FrontendTicketingLinkInUserLocale } from "../../../../../helpers/redirection";
import { useTranslate } from "../../../../../hooks/useTranslate";

import { PeerToPeerDonateOrFundraiseOption } from "./PeerToPeerDonateOrFundraiseOption";

export type PeerToPeerGeneralCampaignContributeOptionsProps = {
  allowTeamCreation: boolean;
  allowFundraiserCreation: boolean;
  hasRegistration: boolean;
  registrationIsMandatory: boolean;
  onDonateButtonClick: () => void;
  onFundraiseButtonClick: () => void;
  redirectUrl?: string | null;
};

export const PeerToPeerGeneralCampaignContributeOptions: FC<PeerToPeerGeneralCampaignContributeOptionsProps> = ({
  allowTeamCreation,
  allowFundraiserCreation,
  hasRegistration,
  registrationIsMandatory,
  onFundraiseButtonClick,
  onDonateButtonClick,
  redirectUrl,
}) => {
  const theme = useTheme();
  const { t } = useTranslate();
  const { locale } = useLocaleContext();

  const openTicketing = useCallback(() => {
    if (redirectUrl) {
      if (isV2FrontendUrl(redirectUrl)) {
        // For now, users that create campaign through registration are using the old flow, but we need to redirect them to the new form once payment is submitted
        const url = new URL(setV2FrontendTicketingLinkInUserLocale(redirectUrl, locale));
        url.searchParams.set("isPeerToPeerV2", "true");
        window.open(url.toString());
      } else {
        window.open(`${process.env.NEXT_PUBLIC_SIMPLYK_URL}/ticketing/${redirectUrl}`);
      }
    }
  }, [locale, redirectUrl]);

  const fundraiseOption = (() => {
    if (!hasRegistration) {
      if (allowTeamCreation && !allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.wantToFundraise"),
          description: t("donationForm", "peerToPeer.description.createTeamOrJoin"),
          buttonText: t("donationForm", "peerToPeer.button.fundraise"),
        };
      }
      if (!allowTeamCreation && allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.wantToFundraise"),
          description: t("donationForm", "peerToPeer.description.createCampaign"),
          buttonText: t("donationForm", "peerToPeer.button.fundraise"),
        };
      }
      if (allowTeamCreation && allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.wantToFundraise"),
          description: t("donationForm", "peerToPeer.description.createTeamJoinOrSolo"),
          buttonText: t("donationForm", "peerToPeer.button.fundraise"),
        };
      }
      return {
        title: t("donationForm", "peerToPeer.title.wantToFundraise"),
        description: t("donationForm", "peerToPeer.description.createTeamJoinOrSolo"),
        buttonText: t("donationForm", "peerToPeer.button.fundraise"),
      };
    } else {
      if (!registrationIsMandatory) {
        return {
          title: t("donationForm", "peerToPeer.title.fundraiseOnly"),
          description: t("donationForm", "peerToPeer.description.fundraiseOnly"),
          buttonText: t("donationForm", "peerToPeer.button.fundraiseOnly"),
        };
      } else {
        return null;
      }
    }
  })();

  const donateOption = (() => {
    if (allowTeamCreation && !allowFundraiserCreation) {
      return {
        title: t("donationForm", "peerToPeer.title.wantToDonate"),
        description: t("donationForm", "peerToPeer.description.giveToTeamOrCampaign"),
        buttonText: t("donationForm", "peerToPeer.button.donate"),
      };
    }
    if (!allowTeamCreation && allowFundraiserCreation) {
      return {
        title: t("donationForm", "peerToPeer.title.wantToDonate"),
        description: t("donationForm", "peerToPeer.description.giveToIndividualOrCampaign"),
        buttonText: t("donationForm", "peerToPeer.button.donate"),
      };
    }
    if (allowTeamCreation && allowFundraiserCreation) {
      return {
        title: t("donationForm", "peerToPeer.title.wantToDonate"),
        description: t("donationForm", "peerToPeer.description.giveToIndividualTeamOrCampaign"),
        buttonText: t("donationForm", "peerToPeer.button.donate"),
      };
    }
    return {
      title: t("donationForm", "peerToPeer.title.wantToDonate"),
      description: t("donationForm", "peerToPeer.description.giveToTeamOrCampaign"),
      buttonText: t("donationForm", "peerToPeer.button.donate"),
    };
  })();

  const registerOption = (() => {
    if (hasRegistration) {
      if (allowTeamCreation && !allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.registerAndFundraise"),
          description: t("donationForm", "peerToPeer.description.createTeamOrJoin"),
          buttonText: t("donationForm", "peerToPeer.button.registerAndFundraise"),
        };
      }
      if (!allowTeamCreation && allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.registerAndFundraise"),
          description: t("donationForm", "peerToPeer.description.createCampaign"),
          buttonText: t("donationForm", "peerToPeer.button.registerAndFundraise"),
        };
      }
      if (allowTeamCreation && allowFundraiserCreation) {
        return {
          title: t("donationForm", "peerToPeer.title.registerAndFundraise"),
          description: t("donationForm", "peerToPeer.description.createCampaign"),
          buttonText: t("donationForm", "peerToPeer.button.registerAndFundraise"),
        };
      }
      return {
        title: t("donationForm", "peerToPeer.title.registerAndFundraise"),
        description: t("donationForm", "peerToPeer.description.createTeamJoinOrSolo"),
        buttonText: t("donationForm", "peerToPeer.button.registerAndFundraise"),
      };
    } else {
      return null;
    }
  })();

  return (
    <Box>
      <Typography variant="h6" sx={{ color: theme.palette.text.form.intense, marginBottom: 2 }}>
        {t("donationForm", "peerToPeer.howDoYouWantToContribute")}
      </Typography>
      <Stack direction="column" spacing={2}>
        <PeerToPeerDonateOrFundraiseOption
          title={donateOption.title}
          description={donateOption.description}
          buttonText={donateOption.buttonText}
          buttonVariant="filled"
          onClick={onDonateButtonClick}
          data-test="donate-button"
        />
        {registerOption && (
          <PeerToPeerDonateOrFundraiseOption
            title={registerOption.title}
            description={registerOption.description}
            buttonText={registerOption.buttonText}
            buttonVariant="outlined"
            onClick={openTicketing}
          />
        )}
        {fundraiseOption && (
          <PeerToPeerDonateOrFundraiseOption
            title={fundraiseOption.title}
            description={fundraiseOption.description}
            buttonText={fundraiseOption.buttonText}
            buttonVariant="outlined"
            onClick={onFundraiseButtonClick}
            data-test="fundraise-button"
          />
        )}
      </Stack>
    </Box>
  );
};
