import { useState, useMemo, useCallback } from "react";

import { CampaignAction } from "@simplyk/common";
import { useRouter } from "next/router";
import { FieldValues, Path, UseFormSetValue } from "react-hook-form";

import { trpc } from "../helpers/trpc";

import { useTranslate } from "./useTranslate";

const getDefaultAction = (
  allowFundraiserCreation: boolean,
  allowTeamCreation: boolean,
  defaultAction?: CampaignAction
): CampaignAction => {
  if (!allowFundraiserCreation && !allowTeamCreation) {
    return CampaignAction.JoinTeam;
  }
  if (!allowTeamCreation) {
    return CampaignAction.IndividualFundraising;
  }
  return defaultAction || CampaignAction.CreateTeam;
};

type FundraiserHookProp<T extends FieldValues> = {
  allowFundraiserCreation: boolean;
  allowTeamCreation: boolean;
  campaignId: string | undefined;
  defaultAction?: CampaignAction;
  setValue?: UseFormSetValue<T>;
  createIndividualFormName?: Path<T>;
};

export const useFundraiser = <T extends FieldValues>({
  allowFundraiserCreation,
  allowTeamCreation,
  campaignId,
  defaultAction,
  setValue,
  createIndividualFormName,
}: FundraiserHookProp<T>) => {
  const [campaignAction, setCampaignAction] = useState<CampaignAction>(
    getDefaultAction(allowFundraiserCreation, allowTeamCreation, defaultAction)
  );

  const toggleCampaignAction = useCallback(
    (value: CampaignAction) => {
      setCampaignAction(value);
      if (value === CampaignAction.CreateTeam && createIndividualFormName) {
        setValue?.(createIndividualFormName, true as never);
      }
    },
    [createIndividualFormName, setValue]
  );
  const { query } = useRouter();

  const { data } = trpc.form_hasTeam.useQuery(
    {
      campaignId: campaignId || "",
    },
    { enabled: Boolean(campaignId) }
  );
  const hasTeam = data || false;

  const { t } = useTranslate();

  const defaultTarget = query.target ? parseInt(query.target as string) : undefined;

  const title = useMemo(() => {
    switch (campaignAction) {
      case CampaignAction.CreateTeam:
        return t("donationForm", "createFundraisingTeam");
      case CampaignAction.IndividualFundraising:
        return t("donationForm", "fundraiseIndividually");
      case CampaignAction.JoinTeam:
        return t("donationForm", "joinTeam");
    }
  }, [campaignAction, t]);

  return {
    campaignAction,
    title,
    hasTeam,
    toggleCampaignAction,
    defaultTarget,
  };
};
