import { StripeRecurringInterval } from "@simplyk/common";

export function getDefaultRecurrenceInterval(hasSuggestedAmounts: {
  oneTime: boolean;
  monthly: boolean;
  yearly: boolean;
}): StripeRecurringInterval | null {
  // If the one time amount is present, we don't select a recurrence interval by default
  if (hasSuggestedAmounts.oneTime) {
    return null;
  }
  // Else if the monthly amount is present, we select the monthly recurrence interval
  if (hasSuggestedAmounts.monthly) {
    return StripeRecurringInterval.month;
  }
  // Else if the yearly amount is present, we select the yearly recurrence interval
  if (hasSuggestedAmounts.yearly) {
    return StripeRecurringInterval.year;
  }

  return null;
}
