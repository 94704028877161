import * as React from "react";

import { getField } from "@simplyk/common";

import {
  FrontendDonationFormContext,
  FrontendDonationFormContextValues,
} from "../contexts/FrontendDonationFormContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { FeatureFlagName } from "../gql/gql-types";
import { getDonationFormFeatureFlagTreatment } from "../helpers/feature-flag";

import { DonationFormOutput } from "@/types/trpc";

interface DonationFormProviderProps {
  donationForm: DonationFormOutput;
}
export const FrontendDonationFormProvider: React.FunctionComponent<
  React.PropsWithChildren<DonationFormProviderProps>
> = ({ children, donationForm }) => {
  const { locale } = useLocaleContext();
  const donationFormField = getField(donationForm.donationFormFields, locale);

  const values: FrontendDonationFormContextValues = {
    donationForm,
    donationFormField,
    areBankPaymentMethodsAllowed:
      getDonationFormFeatureFlagTreatment(donationForm.featureFlagTreatments, FeatureFlagName.IsPaymentByPadAllowed) ??
      false,
  };

  return <FrontendDonationFormContext.Provider value={values}>{children}</FrontendDonationFormContext.Provider>;
};
