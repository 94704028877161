import { FC } from "react";

import { Stack } from "@mui/material";

import { useMediaQuery } from "../../../hooks/useMediaQuery";

import { BaseFormV2Step1Layout, BaseFormV2Step1LayoutProps } from "./BaseFormV2Step1Layout";

export type PeerToPeerFormV2TeamCampaignStep1LayoutProps = Omit<BaseFormV2Step1LayoutProps, "slots"> & {
  slots: {
    banner?: React.ReactNode;
    organizationLogo: React.ReactNode;
    summary: React.ReactNode;
    teamMemberCampaigns: React.ReactNode;
    hostedBy: React.ReactNode;
    helpFundraise: React.ReactNode;
    donationAmounts: React.ReactNode;
    versionControlAlert: React.ReactNode;
    checkoutFooter: React.ReactNode;
  };
};

export const PeerToPeerFormV2TeamCampaignStep1Layout: FC<PeerToPeerFormV2TeamCampaignStep1LayoutProps> = ({
  slots,
  isOneColumn,
  isEmbed,
  ...rest
}) => {
  const { isSmallScreen } = useMediaQuery();

  return (
    <BaseFormV2Step1Layout
      {...rest}
      slots={{
        info: (
          <Stack alignItems="flex-start" width="100%" gap={2}>
            {slots.summary}
            {!isSmallScreen && !isOneColumn && !isEmbed && slots.teamMemberCampaigns}
            {!isSmallScreen && !isOneColumn && !isEmbed && slots.hostedBy}
          </Stack>
        ),
        order: (
          <Stack width="100%" gap={2}>
            {slots.helpFundraise}
            {slots.donationAmounts}
            {slots.versionControlAlert}
            {(isSmallScreen || isOneColumn || isEmbed) && slots.teamMemberCampaigns}
            {(isSmallScreen || isOneColumn || isEmbed) && slots.hostedBy}
          </Stack>
        ),
        checkoutFooter: slots.checkoutFooter,
      }}
    />
  );
};
