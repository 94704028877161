import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from "react";

import { Box, Grid, Stack, useTheme } from "@mui/material";
import { AvailablePaymentCurrency, RouteBuilders } from "@simplyk/common";
import InfiniteScroll from "react-infinite-scroll-component";

import { useLocaleContext } from "@/contexts/LocaleContext";
import { InformationDialog, InformationDialogProps } from "@/design-system/Dialog/InformationDialog";
import { SearchField } from "@/design-system/SearchField";
import { Typography } from "@/design-system/Typography";
import { FundraiserSkeleton } from "@/features/FormV2/Step1/PeerToPeer/FundraiserSkeleton";
import { GeneralFundraiser, GeneralFundraiserProps } from "@/features/FormV2/Step1/PeerToPeer/GeneralFundraiser";
import {
  IndividualFundraiser,
  IndividualFundraiserProps,
} from "@/features/FormV2/Step1/PeerToPeer/IndividualFundraiser";
import { TeamFundraiser, TeamFundraiserProps } from "@/features/FormV2/Step1/PeerToPeer/TeamFundraiser";
import { trpc } from "@/helpers/trpc";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Trans, useTranslate } from "@/hooks/useTranslate";

type CampaignType = { type: "general" | "team" | "individual" };
export type SearchedCampaign =
  | (GeneralFundraiserProps & CampaignType)
  | (TeamFundraiserProps & CampaignType)
  | (IndividualFundraiserProps & CampaignType);

export type FundraisersInformationDialogProps = Omit<
  StatelessFundraisersInformationDialogProps,
  | "isLoading"
  | "generalCampaign"
  | "teamCampaigns"
  | "totalTeamCampaigns"
  | "hasMoreTeamCampaigns"
  | "onFetchMoreTeamCampaigns"
  | "individualCampaigns"
  | "totalIndividualCampaigns"
  | "hasMoreIndividualCampaigns"
  | "onFetchMoreIndividualCampaigns"
  | "searchedCampaigns"
  | "hasMoreSearchedCampaigns"
  | "onFetchMoreSearchedCampaigns"
> & {
  campaignId: string;
  formCurrency: AvailablePaymentCurrency;
};

export const FundraisersInformationDialog: FC<FundraisersInformationDialogProps> = ({
  open,
  search,
  onSearchChange,
  campaignId,
  mode,
  formCurrency,
  ...props
}) => {
  const { isoLocale } = useLocaleContext();
  const [internalSearch, setInternalSearch] = useState(search);
  const [internalMode, setInternalMode] = useState(mode);

  useEffect(() => {
    // Only update the internal mode when the dialog is open, so the dialog doesn't render differently when closing
    if (open) {
      setInternalMode(mode);
    }
  }, [mode, open]);

  useEffect(() => {
    if (!open) {
      setInternalSearch(search);
    }
  }, [search, open]);

  const handleOnSearchChange = useCallback(
    (search: string) => {
      setInternalSearch(search);
      onSearchChange(search);
    },
    [onSearchChange]
  );

  const { data: generalCampaignData, isFetching: isGeneralCampaignFetching } =
    trpc.getPrimaryPeerToPeerCampaign.useQuery(
      {
        campaignId,
      },
      {
        enabled: open,
      }
    );

  const {
    data: teamCampaignsData,
    isFetching: isTeamCampaignsFetching,
    isFetchingNextPage: isTeamCampaignsFetchingNextPage,
    hasNextPage: hasMoreTeamCampaigns,
    fetchNextPage: fetchMoreTeamCampaigns,
  } = trpc.getTeamPeerToPeerCampaigns.useInfiniteQuery(
    {
      campaignId,
      limit: 10,
    },
    {
      enabled: open,
      initialCursor: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
  );

  const {
    data: individualCampaignsData,
    isFetching: isIndividualCampaignsFetching,
    isFetchingNextPage: isIndividualCampaignsFetchingNextPage,
    hasNextPage: hasMoreIndividualCampaigns,
    fetchNextPage: fetchMoreIndividualCampaigns,
  } = trpc.getIndividualPeerToPeerCampaigns.useInfiniteQuery(
    {
      campaignId,
      limit: 10,
    },
    {
      enabled: open,
      initialCursor: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
  );

  const {
    data: searchedCampaignsData,
    isFetching: isSearchedCampaignsFetching,
    isFetchingNextPage: isSearchedCampaignsFetchingNextPage,
    hasNextPage: hasMoreSearchedCampaigns,
    fetchNextPage: fetchMoreSearchedCampaigns,
  } = trpc.searchPeerToPeerCampaigns.useInfiniteQuery(
    {
      campaignId,
      limit: 10,
      search,
    },
    {
      enabled: open && !!search,
      initialCursor: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
  );

  const generalCampaign = (() => {
    if (generalCampaignData) {
      const { path, ...data } = generalCampaignData;

      return {
        ...data,
        href: RouteBuilders.buildPeerToPeerV2GeneralCampaignLink({ isoLocale, path }).path,
        currency: formCurrency,
      };
    }

    return null;
  })();

  const teamCampaigns =
    teamCampaignsData?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2TeamCampaignLink({ isoLocale, path: x.path }).path,
        currency: formCurrency,
      })) ?? [];

  const individualCampaigns =
    individualCampaignsData?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path: x.path }).path,
        currency: formCurrency,
      })) ?? [];

  const searchedCampaigns =
    searchedCampaignsData?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2CampaignLink({ type: x.type, isoLocale, path: x.path }).path,
        currency: formCurrency,
      })) ?? [];

  return (
    <StatelessFundraisersInformationDialog
      {...props}
      mode={internalMode}
      open={open}
      search={internalSearch}
      onSearchChange={handleOnSearchChange}
      isLoading={
        isGeneralCampaignFetching ||
        (isTeamCampaignsFetching && !isTeamCampaignsFetchingNextPage) ||
        (isIndividualCampaignsFetching && !isIndividualCampaignsFetchingNextPage) ||
        internalSearch !== search ||
        (isSearchedCampaignsFetching && !isSearchedCampaignsFetchingNextPage)
      }
      generalCampaign={generalCampaign}
      teamCampaigns={teamCampaigns}
      hasMoreTeamCampaigns={hasMoreTeamCampaigns ?? false}
      onFetchMoreTeamCampaigns={fetchMoreTeamCampaigns}
      individualCampaigns={individualCampaigns}
      hasMoreIndividualCampaigns={hasMoreIndividualCampaigns ?? false}
      onFetchMoreIndividualCampaigns={fetchMoreIndividualCampaigns}
      searchedCampaigns={searchedCampaigns}
      hasMoreSearchedCampaigns={hasMoreSearchedCampaigns ?? false}
      onFetchMoreSearchedCampaigns={fetchMoreSearchedCampaigns}
    />
  );
};

export type StatelessFundraisersInformationDialogProps = Pick<InformationDialogProps, "open" | "onClose"> & {
  mode: "find-fundraiser-campaign" | "donate-to-campaign";
  isLoading?: boolean;
  search: string;
  onSearchChange: (search: string) => void;
  generalCampaign: GeneralFundraiserProps | null;
  teamCampaigns: TeamFundraiserProps[];
  individualCampaigns: IndividualFundraiserProps[];
  hasMoreTeamCampaigns: boolean;
  hasMoreIndividualCampaigns: boolean;
  onFetchMoreIndividualCampaigns: () => void;
  onFetchMoreTeamCampaigns: () => void;
  searchedCampaigns: SearchedCampaign[];
  hasMoreSearchedCampaigns: boolean;
  onFetchMoreSearchedCampaigns: () => void;
};

export const StatelessFundraisersInformationDialog: FC<StatelessFundraisersInformationDialogProps> = ({
  mode,
  isLoading = false,
  generalCampaign,
  teamCampaigns,
  individualCampaigns,
  search,
  onSearchChange,
  open,
  onClose,
  hasMoreTeamCampaigns,
  hasMoreIndividualCampaigns,
  onFetchMoreIndividualCampaigns,
  onFetchMoreTeamCampaigns,
  searchedCampaigns,
  hasMoreSearchedCampaigns,
  onFetchMoreSearchedCampaigns,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();

  const { isSmallScreen } = useMediaQuery();

  const hasTeamCampaigns = isLoading || teamCampaigns.length > 0;
  const hasIndividualCampaigns = isLoading || individualCampaigns.length > 0;

  // On mobile, only show individual campaigns when all team campaigns have been loaded
  const showIndividualCampaigns = isSmallScreen
    ? hasIndividualCampaigns && !hasMoreTeamCampaigns
    : hasIndividualCampaigns;

  const handleFetchMoreCampaigns = useCallback(() => {
    if (isSmallScreen) {
      if (hasMoreTeamCampaigns) {
        onFetchMoreTeamCampaigns();
      } else {
        onFetchMoreIndividualCampaigns();
      }
    } else {
      onFetchMoreTeamCampaigns();
      onFetchMoreIndividualCampaigns();
    }
  }, [isSmallScreen, hasMoreTeamCampaigns, onFetchMoreTeamCampaigns, onFetchMoreIndividualCampaigns]);

  const renderSearchContent = () => {
    const infiniteScrollLoader = (
      <Stack gap={1} marginBlockStart={1}>
        {Array.from({ length: 3 }).map((_, index) => (
          <FundraiserSkeleton key={index} />
        ))}
      </Stack>
    );

    return (
      <InfiniteScroll
        dataLength={searchedCampaigns.length}
        next={onFetchMoreSearchedCampaigns}
        hasMore={hasMoreSearchedCampaigns}
        loader={infiniteScrollLoader}
        height={isSmallScreen ? "70dvh" : 460}
        style={{ padding: theme.spacing(3) }}
      >
        <Stack gap={3}>
          <SearchField
            value={search}
            onChange={onSearchChange}
            placeholder={t("donationForm", "peerToPeer.fundraisersDialog.searchPlaceholder")}
          />
          <Stack spacing={1}>
            {isLoading ? (
              Array.from({ length: 6 }).map((_, index) => <FundraiserSkeleton key={index} />)
            ) : searchedCampaigns.length > 0 ? (
              searchedCampaigns.map(({ type, ...campaign }, index) => (
                <Fragment key={index}>
                  {type === "general" && <GeneralFundraiser {...(campaign as GeneralFundraiserProps)} />}
                  {type === "team" && <TeamFundraiser {...(campaign as TeamFundraiserProps)} />}
                  {type === "individual" && <IndividualFundraiser {...(campaign as IndividualFundraiserProps)} />}
                </Fragment>
              ))
            ) : (
              <Typography variant="body2" vibe="text-form-quiet">
                <Trans
                  space="donationForm"
                  i18nKey="peerToPeer.fundraisers.searchNoResults"
                  components={{ sb: <Typography variant="subtitle2" vibe="text-form-quiet" component="span" /> }}
                />
              </Typography>
            )}
          </Stack>
        </Stack>
      </InfiniteScroll>
    );
  };

  const renderFundraisersByType = () => {
    const renderLoadingFundraisers = (): ReactNode => (
      <Stack gap={1}>
        {Array.from({ length: 3 }).map((_, index) => (
          <FundraiserSkeleton key={index} />
        ))}
      </Stack>
    );

    const infiniteScrollLoader = (
      <Box marginBlockStart={1}>
        {isSmallScreen ? (
          renderLoadingFundraisers()
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} md={hasIndividualCampaigns ? 6 : 12}>
              {hasMoreTeamCampaigns && renderLoadingFundraisers()}
            </Grid>
            <Grid item xs={12} md={hasTeamCampaigns ? 6 : 12}>
              {hasMoreIndividualCampaigns && renderLoadingFundraisers()}
            </Grid>
          </Grid>
        )}
      </Box>
    );

    return (
      <InfiniteScroll
        dataLength={
          isSmallScreen
            ? teamCampaigns.length + individualCampaigns.length
            : Math.max(teamCampaigns.length, individualCampaigns.length)
        }
        next={handleFetchMoreCampaigns}
        hasMore={hasMoreTeamCampaigns || hasMoreIndividualCampaigns}
        loader={infiniteScrollLoader}
        height={isSmallScreen ? "70dvh" : 460}
        style={{ padding: theme.spacing(3) }}
      >
        <Stack gap={3}>
          {(hasTeamCampaigns || hasIndividualCampaigns) && (
            <SearchField
              value={search}
              onChange={onSearchChange}
              placeholder={t("donationForm", "peerToPeer.fundraisersDialog.searchPlaceholder")}
            />
          )}
          <Stack gap={1}>
            <Typography variant="subtitle1" vibe="text-form-moderate">
              {t("donationForm", "peerToPeer.fundraisersDialog.generalCampaignLabel")}
            </Typography>
            {isLoading ? (
              <FundraiserSkeleton />
            ) : (
              !!generalCampaign && (
                <GeneralFundraiser
                  {...generalCampaign}
                  href={`${generalCampaign.href}?donate=true`}
                  data-test="general-campaign-fundraiser"
                />
              )
            )}
          </Stack>
          <Grid container spacing={3}>
            {hasTeamCampaigns && (
              <Grid item xs={12} md={hasIndividualCampaigns ? 6 : 12}>
                <Stack gap={1}>
                  <Typography variant="subtitle1" vibe="text-form-moderate">
                    {t("donationForm", "peerToPeer.fundraisersDialog.teamCampaignsLabel")}
                  </Typography>
                  <Stack gap={1}>
                    {isLoading
                      ? Array.from({ length: 4 }).map((_, index) => <FundraiserSkeleton key={index} />)
                      : teamCampaigns.map((campaign, index) => <TeamFundraiser key={index} {...campaign} />)}
                  </Stack>
                </Stack>
              </Grid>
            )}
            {showIndividualCampaigns && (
              <Grid item xs={12} md={hasTeamCampaigns ? 6 : 12}>
                <Stack gap={1}>
                  <Typography variant="subtitle1" vibe="text-form-moderate">
                    {t("donationForm", "peerToPeer.fundraisersDialog.individualCampaignsLabel")}
                  </Typography>
                  <Stack gap={1}>
                    {isLoading
                      ? Array.from({ length: 4 }).map((_, index) => <FundraiserSkeleton key={index} />)
                      : individualCampaigns.map((campaign, index) => (
                          <IndividualFundraiser key={index} {...campaign} />
                        ))}
                  </Stack>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Stack>
      </InfiniteScroll>
    );
  };

  const title = () => {
    return (
      <Typography variant="h6">
        {mode === "find-fundraiser-campaign"
          ? t("donationForm", "peerToPeer.fundraisersDialog.fundraisersTitle")
          : t("donationForm", "peerToPeer.fundraisersDialog.donateToCampaignTitle")}
      </Typography>
    );
  };

  return (
    <InformationDialog title={title()} open={open} onClose={onClose} noPadding>
      {search ? renderSearchContent() : renderFundraisersByType()}
    </InformationDialog>
  );
};
