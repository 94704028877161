/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useContext, useEffect, useState } from "react";

import { FormType, RouteBuilders } from "@simplyk/common";
import { parseAsBoolean, parseAsString, parseAsStringLiteral, useQueryState } from "nuqs";

import { useLocaleContext } from "../../../contexts/LocaleContext";
import { trpc } from "../../../helpers/trpc";
import { useTranslate } from "../../../hooks/useTranslate";
import { useFormV2Context } from "../context/FormV2Context";
import { HeartsAnimationProvider } from "../context/heartsAnimationContext";

import { DonationFormV2CheckoutFooter } from "./CheckoutFooter/DonationFormV2CheckoutFooter";
import { PeerToPeerDonateOrFundraiseOption } from "./DonationForm/PeerToPeer/PeerToPeerDonateOrFundraiseOption";
import { FundraisersTile } from "./PeerToPeer/FundraisersTile";
import { IndividualFundraiser } from "./PeerToPeer/IndividualFundraiser";
import { TeamMemberCampaignsInformationDialog } from "./PeerToPeer/TeamMemberCampaignsInformationDialog";
import { VersionControlAlertV2 } from "./VersionControlAlertV2";

import { NotificationBannerHeightContext } from "@/components/NotificationBanners/NotificationBannerHeightContext";
import { useUtmForm } from "@/features/FormV2/hooks/useUtmForm";
import { Amounts } from "@/features/FormV2/Step1/DonationForm/Amounts";
import { HostedBy } from "@/features/FormV2/Step1/DonationForm/PeerToPeer/HostedBy/HostedBy";
import { useDonationFirstStepSubmit } from "@/features/FormV2/Step1/DonationForm/useDonationFirststepSubmit";
import { FormV2Banner } from "@/features/FormV2/Step1/FormV2Banner";
import { FormV2LogoChip } from "@/features/FormV2/Step1/FormV2LogoChip";
import { FormV2Summary } from "@/features/FormV2/Step1/FormV2Summary";
import { CampaignIsLiveDialog } from "@/features/FormV2/Step1/PeerToPeer/CampaignIsLiveDialog/CampaignIsLiveDialog";
import { useCampaignCreatedSuccess } from "@/features/FormV2/Step1/PeerToPeer/CampaignIsLiveDialog/useCampaignCreatedSuccess";
import { FundraiseDialog } from "@/features/FormV2/Step1/PeerToPeer/FundraiseDialog/FundraiseDialog";
import { PeerToPeerFormV2TeamCampaignStep1Layout } from "@/features/FormV2/Step1/PeerToPeerFormV2TeamCampaignStep1Layout";
import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";

export type PeerToPeerFormV2TeamCampaignStep1Props = {
  isEmbed?: boolean;
};

export const PeerToPeerFormV2TeamCampaignStep1: FC<PeerToPeerFormV2TeamCampaignStep1Props> = ({ isEmbed = false }) => {
  const { t } = useTranslate();
  const { isoLocale } = useLocaleContext();
  const { formObject, hasBanner, formCurrency } = useFormV2Context(FormType.DonationForm);
  const { isExpanded, bannerHeight } = useContext(NotificationBannerHeightContext);
  const { isPreview } = usePreviewContext();
  const { canSubmitFirstStep } = useDonationFirstStepSubmit();

  const [searchQuery, setSearchQuery] = useQueryState("search", parseAsString.withDefault(""));
  const [teamMemberCampaignsSearchQuery, setTeamMemberCampaignsSearchQuery] = useQueryState(
    "teamMemberCampaignsSearch",
    parseAsString.withDefault("")
  );
  const [searchText, setSearchText] = useState(searchQuery);
  const [teamMemberCampaignsSearchText, setTeamMemberCampaignsSearchText] = useState(teamMemberCampaignsSearchQuery);
  const [fundraiseQuery, setFundraiseQuery] = useQueryState(
    "fundraise",
    parseAsBoolean.withDefault(false).withOptions({ history: "push" })
  );
  const [teamMemberCampaignsQuery, setTeamMemberCampaignsQuery] = useQueryState(
    "teamMemberCampaigns",
    parseAsStringLiteral(["view-all"] as const).withOptions({ history: "push" })
  );

  const utm = useUtmForm();

  const { isCampaignIsLiveDialogOpen, handleCampaignIsLiveDialogClose, formLink } = useCampaignCreatedSuccess(false);

  const isTeamMemberCampaignsModalOpen = teamMemberCampaignsQuery === "view-all";

  const { data: getTeamMemberCampaignsResponse } = trpc.getTeamMemberCampaigns.useQuery({
    teamId: formObject.id!,
    cursor: 0,
    limit: 6,
  });

  const teamMemberCampaigns = getTeamMemberCampaignsResponse ?? { items: [], hasMore: false };

  const { data: searchedCampaignsData, isFetching: isSearchedCampaignsFetching } =
    trpc.searchPeerToPeerCampaigns.useInfiniteQuery(
      {
        campaignId: formObject.campaignId!,
        limit: 10,
        search: searchQuery,
        teamId: formObject.id!,
      },
      {
        enabled: !!searchQuery && !isTeamMemberCampaignsModalOpen,
        initialCursor: 0,
        getNextPageParam: (lastPage) => lastPage?.nextCursor,
      }
    );

  const searchedCampaigns =
    searchedCampaignsData?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.filter((x) => x.type !== "team")
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path: x.path }).path,
      })) ?? [];

  const searchedTeamMemberCampaigns = searchedCampaigns ?? { items: [], hasMore: false };

  const shouldDisplayTeamMemberCampaigns = teamMemberCampaigns.items.length > 0;

  useEffect(() => {
    // Debounce the search to avoid spamming the server with requests
    let timeout: NodeJS.Timeout;

    if (searchQuery !== searchText) {
      timeout = setTimeout(() => {
        setSearchQuery(searchText);
      }, 400);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  useEffect(() => {
    // Debounce the search to avoid spamming the server with requests
    let timeout: NodeJS.Timeout;

    if (teamMemberCampaignsSearchQuery !== teamMemberCampaignsSearchText) {
      timeout = setTimeout(() => {
        setTeamMemberCampaignsSearchQuery(teamMemberCampaignsSearchText);
      }, 400);
    }

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamMemberCampaignsSearchText]);

  const renderTeamMemberCampaignsTile = () => (
    <FundraisersTile
      isLoading={isSearchedCampaignsFetching}
      searchText={searchText}
      onSearchTextChange={setSearchText}
      onViewAllClick={() => setTeamMemberCampaignsQuery("view-all")}
      showViewAllButton={teamMemberCampaigns?.hasMore}
      mode="show-only-individual-campaigns"
    >
      {(searchQuery ? searchedTeamMemberCampaigns : teamMemberCampaigns.items).map(
        ({ path, ...teamMemberCampaign }, index) => {
          return (
            <IndividualFundraiser
              key={index}
              {...teamMemberCampaign}
              href={RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path }).path}
              imageSrc={teamMemberCampaign.imageSrc}
              campaignTitle={teamMemberCampaign.campaignTitle}
              totalRaised={teamMemberCampaign.totalRaised}
              isPartOfTeam={true}
              currency={formCurrency}
            />
          );
        }
      )}
    </FundraisersTile>
  );

  return (
    <HeartsAnimationProvider>
      <PeerToPeerFormV2TeamCampaignStep1Layout
        isEmbed={isEmbed}
        contextDrawerHeight={isExpanded ? bannerHeight : 0}
        slots={{
          banner: hasBanner ? <FormV2Banner /> : undefined,
          organizationLogo: <FormV2LogoChip />,
          summary: <FormV2Summary hideBanner={true} hideLogoChip={true} forceInlined={isEmbed} />,
          teamMemberCampaigns: shouldDisplayTeamMemberCampaigns ? renderTeamMemberCampaignsTile() : undefined,
          hostedBy: <HostedBy campaignId={formObject.campaignId!} teamId={formObject.teamId ?? undefined} />,
          helpFundraise:
            formObject.campaign?.allowTeamCreation || formObject.campaign?.allowFundraiserCreation ? (
              <PeerToPeerDonateOrFundraiseOption
                title={t("donationForm", "peerToPeer.title.wantToFundraise")}
                description={t("donationForm", "peerToPeer.description.joinFundraisingTeam")}
                buttonText={t("donationForm", "peerToPeer.button.wantToFundraise")}
                buttonVariant="outlined"
                onClick={() => setFundraiseQuery(true)}
                data-test="fundraise-button"
              />
            ) : undefined,
          donationAmounts: <Amounts />,
          versionControlAlert: <VersionControlAlertV2 />,
          checkoutFooter: <DonationFormV2CheckoutFooter />,
        }}
        showCheckoutFooter={canSubmitFirstStep && !isPreview}
        utm={utm}
      />
      <TeamMemberCampaignsInformationDialog
        campaignId={formObject.campaignId!}
        teamId={formObject.id!}
        open={isTeamMemberCampaignsModalOpen}
        search={teamMemberCampaignsSearchQuery}
        onSearchChange={setTeamMemberCampaignsSearchText}
        formCurrency={formCurrency}
        onClose={() => {
          setTeamMemberCampaignsQuery(null);
          setTeamMemberCampaignsSearchQuery(null);
        }}
      />

      <FundraiseDialog
        open={fundraiseQuery}
        onClose={() => setFundraiseQuery(null)}
        campaignId={formObject.campaignId as string}
        allowTeamCreation={Boolean(formObject.campaign?.allowTeamCreation)}
        allowFundraiserCreation={Boolean(formObject.campaign?.allowFundraiserCreation)}
        defaultTeamId={formObject.id}
      />
      {/* Display the campaign is live dialog if the p2p campaign is created successfully */}
      <CampaignIsLiveDialog
        open={isCampaignIsLiveDialogOpen}
        onClose={handleCampaignIsLiveDialogClose}
        isTeamCampaign={true}
        isIndividualCampaign={false}
        fundraiserName={formObject.donationFormFields?.[0]?.title}
        formLink={formLink}
      />
    </HeartsAnimationProvider>
  );
};
