import { Children, FC, ReactNode } from "react";

import { Grid, Stack } from "@mui/material";

import { FundraiserSkeleton } from "./FundraiserSkeleton";

import { Button } from "@/design-system/Button";
import { SearchField } from "@/design-system/SearchField";
import { Typography } from "@/design-system/Typography";
import { Tile, TileProps } from "@/features/FormV2/sharedComponents/Tile/Tile";
import { Trans, useTranslate } from "@/hooks/useTranslate";

export type FundraisersTileProps = Omit<TileProps, "ref"> & {
  isLoading?: boolean;
  canSearch?: boolean;
  children: ReactNode;
  onViewAllClick: () => void;
  searchText: string;
  onSearchTextChange: (searchText: string) => void;
  showViewAllButton?: boolean;
  mode?: "show-team-and-individual-campaigns" | "show-only-individual-campaigns";
};

export const FundraisersTile: FC<FundraisersTileProps> = ({
  isLoading = false,
  searchText,
  onSearchTextChange,
  children,
  onViewAllClick,
  showViewAllButton = false,
  // eslint-disable-next-line sonarjs/no-duplicate-string
  mode = "show-team-and-individual-campaigns",
  ...rest
}) => {
  const { t } = useTranslate();

  const renderFundraisers = () => {
    if (isLoading) {
      return (
        <Grid container spacing={1.5}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <FundraiserSkeleton />
            </Grid>
          ))}
        </Grid>
      );
    }

    if (!!searchText && Children.count(children) === 0) {
      return (
        <Typography variant="body2" vibe="text-form-quiet">
          <Trans
            space="donationForm"
            i18nKey="peerToPeer.fundraisers.searchNoResults"
            components={{ sb: <Typography variant="subtitle2" vibe="text-form-quiet" component="span" /> }}
          />
        </Typography>
      );
    }

    return (
      <Grid container spacing={1.5}>
        {Children.map(children, (child, index) => (
          <Grid item xs={12} sm={6} key={index}>
            {child}
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Tile {...rest}>
      <Stack alignItems="flex-start" gap={2}>
        <Typography variant="subtitle1" vibe="text-form-quiet">
          {mode === "show-team-and-individual-campaigns"
            ? t("donationForm", "peerToPeer.fundraisersCard.title")
            : t("donationForm", "peerToPeer.teamMemberCampaigns.title")}
        </Typography>
        {showViewAllButton && (
          <SearchField
            value={searchText}
            onChange={onSearchTextChange}
            placeholder={
              mode === "show-team-and-individual-campaigns"
                ? t("donationForm", "peerToPeer.fundraisersCard.searchPlaceholder")
                : t("donationForm", "peerToPeer.teamMemberCampaignsCard.searchPlaceholder")
            }
          />
        )}
        {renderFundraisers()}
        {showViewAllButton && (
          <Button variant="outlined" vibe="brand" size="small" onClick={onViewAllClick}>
            {mode === "show-team-and-individual-campaigns"
              ? t("donationForm", "peerToPeer.fundraisersCard.viewAll")
              : t("donationForm", "peerToPeer.teamMemberCampaignsCard.viewAll")}
          </Button>
        )}
      </Stack>
    </Tile>
  );
};
