import { FC, useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material";
import { FormType } from "@simplyk/common";

import { FormV2Provider } from "@/features/FormV2/context/FormV2Provider";
import { createFormV2Theme } from "@/features/FormV2/theme/formV2Theme";
import { FormV2StepKey } from "@/features/FormV2/types/FormV2StepKey";
import { StripeProvider } from "@/src/providers/StripeProvider";
import { DonationFormOutput, TicketingOutput } from "@/types/trpc";

export type FormV2ProvidersProps = {
  step?: FormV2StepKey;
  children: React.ReactNode | ((currentStep: FormV2StepKey) => React.ReactNode);
} & (
  | { type: FormType.Ticketing; formObject: TicketingOutput }
  | { type: FormType.DonationForm; formObject: DonationFormOutput }
);

export const FormV2Providers: FC<FormV2ProvidersProps> = ({ children, type, formObject, step }) => {
  const theme = createFormV2Theme(formObject.color);

  const [currentStep, setCurrentStep] = useState<FormV2StepKey>(step ?? "selection");

  useEffect(() => {
    if (step) {
      setCurrentStep(step);
    }
  }, [step]);

  return (
    <ThemeProvider theme={theme}>
      <FormV2Provider type={type} formObject={formObject} currentStep={currentStep} setCurrentStep={setCurrentStep}>
        <StripeProvider>{typeof children === "function" ? children(currentStep) : children}</StripeProvider>
      </FormV2Provider>
    </ThemeProvider>
  );
};
