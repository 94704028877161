import { FC, PropsWithChildren, useMemo } from "react";

import { useTheme } from "@mui/material/styles";
import classNames from "classnames";

import { zeffyAvatarClasses, StyledAvatar } from "./styles";
import { AvatarProps } from "./types";

import { getRandomColor } from "@/helpers/colors";

export const Avatar: FC<PropsWithChildren<AvatarProps>> = ({
  children,
  className,
  color,
  size = "medium",
  randomColorSetting,
  ...rest
}) => {
  const theme = useTheme();

  const randomizedColor = useMemo(
    () => {
      if (!randomColorSetting) {
        return color;
      }
      return getRandomColor(color, randomColorSetting);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [color]
  );

  return (
    <StyledAvatar
      {...rest}
      className={classNames(className, zeffyAvatarClasses.avatar, zeffyAvatarClasses[size])}
      style={{
        backgroundColor: randomizedColor,
        color: randomizedColor ? theme.palette.getContrastText(randomizedColor) : undefined,
      }}
    >
      {children}
    </StyledAvatar>
  );
};
