import { FC } from "react";

import { Stack } from "@mui/material";
import { getIsoLocaleFromCountry, Utils } from "@simplyk/common";
import { useRouter } from "next/router";

import { Button } from "../../../components/design-system/Button";
import { Typography } from "../../../components/design-system/Typography";
import { useLocaleContext } from "../../../contexts/LocaleContext";
import { Locales } from "../../../gql/gql-types";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useFormV2Context } from "../context/FormV2Context";

import { TicketingFieldOutput, DonationFormFieldOutput } from "@/types/trpc";

export const FormV2LanguageSelect: FC = () => {
  const router = useRouter();
  const { locale } = useLocaleContext();
  const { formObject, formFields } = useFormV2Context();

  const shouldDisplayLanguageSelect = formFields.length > 1;
  const translationLocales = Utils.filterAndMap<TicketingFieldOutput | DonationFormFieldOutput, Locales>(
    formFields,
    (field) => field.locale !== locale,
    (field) => field.locale
  );

  const translateForm = (translationLocale: Locales) => {
    // Update the locale in the URL
    router.push(router.asPath, router.asPath, {
      locale: getIsoLocaleFromCountry(formObject.organization?.country, translationLocale),
    });
  };

  if (!shouldDisplayLanguageSelect) {
    return null;
  }

  return <StatelessFormV2LanguageSelect locales={translationLocales} onClick={translateForm} />;
};

export type StatelessFormV2LanguageSelectProps = {
  locales: Locales[];
  onClick: (translationLocale: Locales) => void;
};

export const StatelessFormV2LanguageSelect: FC<StatelessFormV2LanguageSelectProps> = ({ locales, onClick }) => {
  const { isSmallScreen } = useMediaQuery();

  return (
    <Stack display="inline-flex" direction="row" gap={1} data-test="form-v2-language-select">
      {locales.map((tLocale) => (
        <Button
          key={tLocale}
          variant="text"
          vibe="neutral"
          size="small"
          sx={(theme) => ({
            height: { xs: 28, md: 40 },
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            boxShadow: theme.boxShadow.base.lightest,
            backgroundColor: theme.palette.surface.neutral.white,
            borderRadius: 1,
          })}
          onClick={() => onClick(tLocale)}
          data-test="ticketing-toggle-locale"
        >
          <Typography vibe="text-form-moderate" variant={isSmallScreen ? "subtitle2" : "subtitle1"}>
            {tLocale}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};
