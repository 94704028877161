/**
 * Get memorable forms colors from user color
 */
export const createUserColors = (baseHexColor) => {
    const { h: baseHue, s: baseSaturation, l: baseLightness } = getHSLFromHex(baseHexColor);
    const isGreyTheme = baseLightness <= 14 || baseLightness >= 95 || baseSaturation <= 10;
    const createColor = (nonAdjustedSaturation, lightness) => {
        const saturation = isGreyTheme ? 0 : nonAdjustedSaturation;
        return `hsl(${baseHue}, ${saturation}%, ${lightness}%)`;
    };
    return {
        textModerate: createColor(74, 21),
        textIntense: createColor(74, 13),
        surfaceQuiet: createColor(100, 95),
        surfaceModerate: createColor(57, 92),
        surfaceBrand: isGreyTheme ? `hsl(${baseHue}, ${baseSaturation}%, 0%)` : `hsl(${baseHue}, ${baseSaturation}%, 40%)`,
    };
};
export function getHSLFromHex(hex) {
    // Remove the '#' if present
    hex = hex.replace("#", "");
    // Parse hex values to get red, green, and blue components
    const red = parseInt(hex.substring(0, 2), 16) / 255;
    const green = parseInt(hex.substring(2, 4), 16) / 255;
    const blue = parseInt(hex.substring(4, 6), 16) / 255;
    // Find the maximum and minimum values to calculate lightness
    const maxColorValue = Math.max(red, green, blue);
    const minColorValue = Math.min(red, green, blue);
    let hue = 0;
    let saturation = 0;
    const lightness = (maxColorValue + minColorValue) / 2;
    // Calculate hue and saturation only if max and min are not equal
    if (maxColorValue !== minColorValue) {
        const colorDifference = maxColorValue - minColorValue;
        // Calculate saturation based on lightness
        saturation =
            lightness > 0.5
                ? colorDifference / (2 - maxColorValue - minColorValue)
                : colorDifference / (maxColorValue + minColorValue);
        // Determine hue based on which color is the max value
        switch (maxColorValue) {
            case red:
                hue = (green - blue) / colorDifference + (green < blue ? 6 : 0);
                break;
            case green:
                hue = (blue - red) / colorDifference + 2;
                break;
            case blue:
                hue = (red - green) / colorDifference + 4;
                break;
        }
        hue /= 6; // Normalize hue to be in the range [0, 1]
    }
    // Convert hue, saturation, and lightness to 0-360, 0-100, and 0-100 ranges respectively
    return {
        h: Math.round(hue * 360),
        s: Math.round(saturation * 100),
        l: Math.round(lightness * 100),
    };
}
