import { createTheme } from "@mui/material/styles";
import { getHSLFromHex } from "@simplyk/common";

import { createFrontendTheme } from "../../../hooks/useTheme";

class PaletteFactory {
  constructor(
    private baseHue: number,
    private isGreyTheme: boolean
  ) {}

  createColor(nonAdjustedSaturation: number, lightness: number) {
    const saturation = this.isGreyTheme ? 0 : nonAdjustedSaturation;
    return `hsl(${this.baseHue}, ${saturation}%, ${lightness}%)`;
  }
}

export const createFormV2Theme = (baseHexColor: string) => {
  const theme = createFrontendTheme(baseHexColor);
  const { h: baseHue, s: baseSaturation, l: baseLightness } = getHSLFromHex(baseHexColor);

  // Determine if the theme should be greyscale because does have any real tone (black, white, grey)
  const isGreyTheme = baseLightness <= 14 || baseLightness >= 95 || baseSaturation <= 10;
  const paletteFactory = new PaletteFactory(baseHue, isGreyTheme);

  return createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      text: {
        ...theme.palette.text,
        form: {
          ...theme.palette.text.form,
          intense: paletteFactory.createColor(74, 13),
          moderate: paletteFactory.createColor(74, 21),
          quiet: paletteFactory.createColor(21, 68),
          supershy: paletteFactory.createColor(100, 40),
        },
        brand: {
          ...theme.palette.text.brand,
          intense: paletteFactory.createColor(74, 14),
          moderate: paletteFactory.createColor(74, 21),
          quiet: paletteFactory.createColor(21, 68),
        },
      },
      border: {
        ...theme.palette.border,
        form: {
          ...theme.palette.border.form,
          moderate: paletteFactory.createColor(39, 82),
          quiet: paletteFactory.createColor(57, 87),
          intense: paletteFactory.createColor(74, 21),
        },
      },
      surface: {
        ...theme.palette.surface,
        form: {
          ...theme.palette.surface.form,
          superintense: paletteFactory.createColor(39, 70),
          intense: paletteFactory.createColor(39, 82),
          moderate: paletteFactory.createColor(57, 92),
          quiet: paletteFactory.createColor(100, 95),
          supershy: paletteFactory.createColor(100, 98),
        },
        brand: {
          ...theme.palette.surface.brand,
          intense: isGreyTheme ? `hsl(${baseHue}, ${baseSaturation}%, 0%)` : `hsl(${baseHue}, ${baseSaturation}%, 40%)`,
        },
      },
    },
    constants: {
      ...theme.constants,
      isFormV2: true,
    },
  });
};
