import { FC } from "react";

import { Fundraiser, FundraiserProps } from "./Fundraiser";

export type GeneralFundraiserProps = Omit<FundraiserProps, "title" | "subtitle"> & {
  organizationName: string;
  campaignTitle: string;
};

export const GeneralFundraiser: FC<GeneralFundraiserProps> = ({ organizationName, campaignTitle, ...rest }) => {
  return <Fundraiser {...rest} title={organizationName} subtitle={campaignTitle} />;
};
