/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useCallback } from "react";

import { Box, ButtonGroup, useTheme } from "@mui/material";
import { CampaignAction } from "@simplyk/common";

import { Tooltip } from "../../../../components/design-system/Tooltip";
import { Typography } from "../../../../components/design-system/Typography";
import { AmplitudeEventParams, useAmplitude } from "../../../../hooks/amplitude/useAmplitude";
import { useMediaQuery } from "../../../../hooks/useMediaQuery";
import { useTranslate } from "../../../../hooks/useTranslate";

import { Button } from "@/design-system/Button";

export interface FundraisingTypeOptionsProps {
  campaignAction: CampaignAction;
  toggleCampaignAction: (action: CampaignAction) => void;
  allowTeamCreation?: boolean;
  allowFundraiserCreation?: boolean;
  hasTeam?: boolean;
  multipleRegistrant?: boolean;
  amplitudeEvents?: Partial<Record<CampaignAction, AmplitudeEventParams>>;
  showCreateTeamFirst?: boolean;
}

export const FundraisingTypeOptions: FC<FundraisingTypeOptionsProps> = ({
  campaignAction,
  toggleCampaignAction,
  allowTeamCreation,
  allowFundraiserCreation,
  hasTeam = false,
  multipleRegistrant = false,
  amplitudeEvents,
  showCreateTeamFirst = false,
}) => {
  const { t } = useTranslate();
  const { logAmplitudeEvent } = useAmplitude();
  const { isSmallScreen } = useMediaQuery();
  const theme = useTheme();

  // Toggles campaign action (team or individual, etc.)
  const handleCampaignActionToggle = useCallback(
    (action: CampaignAction) => () => {
      const amplitudeEvent = amplitudeEvents?.[action];
      if (amplitudeEvent) {
        logAmplitudeEvent(amplitudeEvent.event, { ...amplitudeEvent.payload, multipleRegistrant });
      }
      toggleCampaignAction(action);
    },
    [amplitudeEvents, logAmplitudeEvent, multipleRegistrant, toggleCampaignAction]
  );

  const createTeamButton = allowTeamCreation && (
    <Button
      fullWidth={!isSmallScreen}
      sx={{
        minWidth: "fit-content",
        backgroundColor: campaignAction === "CreateTeam" ? theme.palette.surface.form.intense : "transparent",
        ["&:hover"]: {
          backgroundColor: `${theme.palette.surface.brand.moderate} !important`,
        },
      }}
      vibe="brand"
      data-test="campaign-create-team-button"
      variant={!isSmallScreen ? "text" : "outlined"}
      onClick={handleCampaignActionToggle(CampaignAction.CreateTeam)}
    >
      {t("donationForm", "createTeam")}
    </Button>
  );

  const createFundraiserButton = allowFundraiserCreation && (
    <Button
      fullWidth={!isSmallScreen}
      sx={{
        minWidth: "fit-content",
        backgroundColor:
          campaignAction === "IndividualFundraising" ? theme.palette.surface.form.intense : "transparent",
        ["&:hover"]: {
          backgroundColor: `${theme.palette.surface.brand.moderate} !important`,
        },
      }}
      vibe="brand"
      data-test="campaign-create-individual-fundraising-button"
      variant={!isSmallScreen ? "text" : "outlined"}
      onClick={handleCampaignActionToggle(CampaignAction.IndividualFundraising)}
    >
      {t("donationForm", multipleRegistrant ? "individualCampaigns" : "individualCampaign")}
    </Button>
  );

  const joinTeamButton = (
    <Button
      sx={{
        minWidth: "fit-content",
        backgroundColor:
          campaignAction === CampaignAction.JoinTeam ? theme.palette.surface.form.superintense : "transparent",
        ["&:hover"]: {
          backgroundColor: `${theme.palette.surface.brand.moderate} !important`,
        },
      }}
      fullWidth={!isSmallScreen}
      vibe="brand"
      data-test="campaign-join-team-button"
      variant={!isSmallScreen ? "text" : "outlined"}
      onClick={handleCampaignActionToggle(CampaignAction.JoinTeam)}
      disabled={!hasTeam}
    >
      {t("donationForm", "joinTeam")}
    </Button>
  );

  return (
    <Box>
      <Typography variant="subtitle2" vibe="text-form-intense" sx={{ marginBottom: isSmallScreen ? 1 : 2 }}>
        {t("donationForm", "peerToPeer.title.fundraise")}
      </Typography>
      {!isSmallScreen ? (
        <ButtonGroup
          variant="contained"
          fullWidth
          sx={{ padding: 0.5, backgroundColor: theme.palette.surface.form.quiet }}
        >
          {showCreateTeamFirst ? createTeamButton : createFundraiserButton}
          <Tooltip title={!hasTeam ? t("donationForm", "peerToPeer.tooltip.noTeamsAvailable") : ""} arrow>
            <Box sx={{ width: "100%" }}>{joinTeamButton}</Box>
          </Tooltip>
          {showCreateTeamFirst ? createFundraiserButton : createTeamButton}
        </ButtonGroup>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            overflowX: "auto",
            gap: 1,
          }}
        >
          {showCreateTeamFirst ? createTeamButton : createFundraiserButton}
          <Tooltip title={!hasTeam ? t("donationForm", "peerToPeer.tooltip.noTeamsAvailable") : ""} arrow>
            <Box sx={{ whiteSpace: "nowrap" }}>{joinTeamButton}</Box>
          </Tooltip>
          {showCreateTeamFirst ? createFundraiserButton : createTeamButton}
        </Box>
      )}
    </Box>
  );
};
