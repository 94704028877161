import { FC } from "react";

import { Fundraiser, FundraiserProps } from "./Fundraiser";

import { useTranslate } from "@/hooks/useTranslate";

export type IndividualFundraiserProps = Omit<FundraiserProps, "title" | "subtitle"> & {
  campaignTitle: string;
  totalRaised: number;
  isPartOfTeam: boolean;
};

export const IndividualFundraiser: FC<IndividualFundraiserProps> = ({
  href,
  imageSrc,
  campaignTitle,
  totalRaised,
  currency,
  isPartOfTeam,
}) => {
  const { t } = useTranslate();

  return (
    <Fundraiser
      href={href}
      imageSrc={imageSrc}
      title={campaignTitle}
      subtitle={
        isPartOfTeam
          ? t("donationForm", "peerToPeer.individualCampaignType.teamMember")
          : t("donationForm", "peerToPeer.individualCampaignType.individual")
      }
      totalRaised={totalRaised}
      currency={currency}
      showFallbackImage
    />
  );
};
