import { FC, useEffect } from "react";

import { Box, Grid, Stack } from "@mui/material";
import { CampaignAction, getField, Ratio } from "@simplyk/common";

import { TeamData } from "../../../../components/CampaignModal/teamData";
import { CreateFundraisingProps } from "../../../../components/CreateFundraising/type";
import { AmountInput } from "../../../../components/design-system/AmountInput";
import { Checkbox } from "../../../../components/design-system/Checkbox";
import { MenuItem } from "../../../../components/design-system/MenuItem";
import { Select } from "../../../../components/design-system/Select";
import { Typography } from "../../../../components/design-system/Typography";
import { ZeffyLoader } from "../../../../components/design-system/ZeffyLoader/ZeffyLoader";
import { ImageUploader } from "../../../../components/ImageUploader/ImageUploader";
import { ImageType } from "../../../../components/ImageUploader/type";
import { MAX_IMAGE_SIZES } from "../../../../constants/image";
import { useLocaleContext } from "../../../../contexts/LocaleContext";
import { getError } from "../../../../helpers/get";
import { trpc } from "../../../../helpers/trpc";
import { isEmail, isMax, isMin, isRequired } from "../../../../helpers/validators";
import { useTranslate } from "../../../../hooks/useTranslate";
import { useFormV2Context } from "../../context/FormV2Context";

import { EmailTextField } from "@/design-system/EmailTextField/EmailTextField";
import { TextField } from "@/design-system/TextField/TextField";

export const FundraisingForm: FC<CreateFundraisingProps<TeamData>> = (props) => {
  const { errors, register, control, setValue, campaignAction, campaignId, teamId, defaultTarget, defaultTeamId } =
    props;
  const { t } = useTranslate();
  const { formCurrency } = useFormV2Context();
  const { locale } = useLocaleContext();
  const amountMax = 1_000_000_000;

  const { data, isLoading: teamsLoading } = trpc.getDonationFormsFromCampaign.useQuery({
    campaignId: campaignId ?? "",
    filters: {
      page: 1,
      search: "",
      limit: 100,
    },
  });

  // Set the team value to the formId if the campaignAction is JoinTeam
  useEffect(() => {
    if (campaignAction === CampaignAction.JoinTeam && defaultTeamId) {
      setValue("team", defaultTeamId, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [defaultTeamId, campaignAction, setValue]);

  return (
    <Stack gap={2}>
      {campaignAction === CampaignAction.CreateTeam && (
        <TextField
          control={control}
          name="teamName"
          placeholder={t("donationForm", "teamName")}
          label={t("donationForm", "teamName")}
          errorMessage={errors.teamName?.message}
          data-test="team-name-input"
        />
      )}
      {campaignAction === CampaignAction.JoinTeam && !teamId && (
        <Select
          name="team"
          label={t("donationForm", "joinTeam")}
          placeholder={t("donationForm", "selectYourTeam")}
          defaultValue={defaultTeamId}
          data-test="select-team-button"
          control={control}
          errorMessage={getError(errors, "selectTeam") ? t("dashboard", "common.isRequired") : undefined}
          rules={[isRequired(t("dashboard", "createNewOrganization.organizationCountryRequired"))]}
          noAsterisk
        >
          {teamsLoading ? (
            <Grid container item justifyContent="center">
              <ZeffyLoader />
            </Grid>
          ) : (
            data?.team?.map((team) => {
              return (
                <MenuItem data-test="team-menu-item" key={team.id} value={team.id}>
                  {getField(team.donationFormFields, locale)?.title}
                </MenuItem>
              );
            })
          )}
        </Select>
      )}
      <AmountInput
        control={control}
        name="campaignTarget"
        label={t("donationForm", "campaignTarget")}
        type="number"
        data-test="campaign-target-input"
        locale={locale}
        currency={formCurrency}
        noAsterisk
        currencyPlacement="start"
        errorMessage={errors.campaignTarget?.message}
        defaultValue={defaultTarget}
        rules={[
          isRequired(t("donationForm", "targetIsRequired")),
          isMin(100, t("donationForm", "targetMin")),
          isMax(amountMax, t("dashboard", "common.isMax", { max: amountMax / 100 })),
        ]}
      />
      <Stack direction={{ md: "row" }} alignItems={{ md: "center" }} gap={2}>
        <Box flex={{ md: "0 0 160px" }}>
          <ImageUploader
            control={control}
            register={register}
            setValue={setValue}
            name="logo"
            importLabel={t("donationForm", "profilePhoto")}
            ratio={Ratio.Square}
            sizes={[100, 100]}
            maxDimensions={MAX_IMAGE_SIZES["logo"]}
            data-test="fundraising-logo-input"
            imageType={ImageType.Logo}
          />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              control={control}
              name="firstName"
              label={t("donationForm", "firstName")}
              placeholder={t("donationForm", "firstName")}
              errorMessage={errors["firstName"]?.message}
              rules={[isRequired(t("donationForm", "firstNameRequired"))]}
              disableAutocomplete
              noAsterisk
              data-test="first-name-input"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              control={control}
              name="lastName"
              label={t("donationForm", "lastName")}
              placeholder={t("donationForm", "lastName")}
              errorMessage={errors["lastName"]?.message}
              rules={[isRequired(t("donationForm", "lastNameRequired"))]}
              disableAutocomplete
              noAsterisk
              data-test="last-name-input"
            />
          </Grid>
          <Grid item xs={12}>
            <EmailTextField
              variant="error"
              control={control}
              name="email"
              type="email"
              label={t("donationForm", "email")}
              placeholder={t("donationForm", "email")}
              errorMessage={errors["email"]?.message}
              checkMisspelledEmail
              noAsterisk
              disableAutocomplete
              rules={[
                isEmail(t("common", "emailFormat"), t("dashboard", "common.emailFormatWrongChar")),
                isRequired(t("donationForm", "emailRequired")),
              ]}
              data-test="email-input"
            />
          </Grid>
        </Grid>
      </Stack>
      {campaignAction === "CreateTeam" && (
        <Grid item xs={12}>
          <Checkbox
            label={
              <Typography variant="subtitle2" vibe="text-form-moderate">
                {t("donationForm", "campaignModal.joinTeamCreated")}
              </Typography>
            }
            size="small"
            control={control}
            name="joinTeamCreated"
            data-test="join-team-created-checkbox"
          />
        </Grid>
      )}
    </Stack>
  );
};
