import { FC } from "react";

import { useRouter } from "next/router";

import { IconButton } from "@/design-system/IconButton";
import { useIsEmbeddedModal, useEmbeddedModal } from "@/hooks/useEmbeddedModal";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Cross } from "@/icons/outlined";

export const CloseEmbedButton: FC = () => {
  const { query } = useRouter();
  const { isSmallScreen } = useMediaQuery();
  const isEmbeddedModal = useIsEmbeddedModal(query);
  const { postMessageClose } = useEmbeddedModal(isEmbeddedModal);

  if (!isEmbeddedModal || !isSmallScreen) {
    return null;
  }

  return (
    <IconButton
      vibe="neutral"
      size="small"
      sx={(theme) => ({
        height: { xs: 28, md: 40 },
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        boxShadow: theme.boxShadow.base.lightest,
        backgroundColor: theme.palette.surface.neutral.white,
        borderRadius: 1,
      })}
      onClick={postMessageClose}
      data-test="ticketing-toggle-locale"
    >
      <Cross />
    </IconButton>
  );
};
