import { Locales } from "@simplyk/common";

export const isV2FrontendUrl = (redirectUrl: string) => {
  return (
    redirectUrl.includes("app.simplyk.io") ||
    redirectUrl.includes("zeffy.com") ||
    redirectUrl.includes("stage-frontend.simplyk.io") ||
    redirectUrl.includes("localhost:8082")
  );
};

export const setV2FrontendTicketingLinkInUserLocale = (redirectUrl: string, locale: Locales) => {
  if (locale === Locales.FR) {
    return redirectUrl.replace("/en/ticketing/", "/fr/ticketing/");
  }
  if (locale === Locales.EN) {
    return redirectUrl.replace("/fr/ticketing/", "/en/ticketing/");
  }
  return redirectUrl;
};

export const redirectToUrlWithCustomGoBack = (
  redirectTo: string,
  { goBackPageName, goBack }: { goBackPageName: string; goBack: string }
) => {
  window.history.replaceState(null, goBackPageName, goBack);
  window.location.assign(redirectTo);
};
