import * as React from "react";

import { DonationFormOutput, DonationFormFieldOutput } from "@/types/trpc";

export interface FrontendDonationFormContextValues {
  donationForm: DonationFormOutput;
  donationFormField: DonationFormFieldOutput | undefined | null;
  areBankPaymentMethodsAllowed: boolean;
}

const defaultValues = {
  donationForm: {} as DonationFormOutput,
  donationFormField: {} as DonationFormFieldOutput,
  shouldDisplayLanguageSelect: false,
  areBankPaymentMethodsAllowed: false,
};

export const FrontendDonationFormContext = React.createContext<FrontendDonationFormContextValues>(defaultValues);
