/* eslint-disable sonarjs/no-identical-functions */
import { FC, useCallback, useEffect, useState } from "react";

import { Stack, useTheme } from "@mui/material";
import { AvailablePaymentCurrency, RouteBuilders } from "@simplyk/common";
import InfiniteScroll from "react-infinite-scroll-component";

import { SearchField } from "../../../../components/design-system/SearchField";
import { Typography } from "../../../../components/design-system/Typography";
import { useLocaleContext } from "../../../../contexts/LocaleContext";

import { FundraiserSkeleton } from "./FundraiserSkeleton";
import { IndividualFundraiser, IndividualFundraiserProps } from "./IndividualFundraiser";

import { InformationDialog, InformationDialogProps } from "@/design-system/Dialog/InformationDialog";
import { trpc } from "@/helpers/trpc";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { Trans, useTranslate } from "@/hooks/useTranslate";

export type TeamMemberCampaignsInformationDialogProps = Pick<
  StatelessTeamMemberCampaignsInformationDialogProps,
  "open" | "onClose"
> & {
  campaignId: string;
  teamId: string;
  search: string;
  onSearchChange: (search: string) => void;
  formCurrency: AvailablePaymentCurrency;
};

export const TeamMemberCampaignsInformationDialog: FC<TeamMemberCampaignsInformationDialogProps> = ({
  campaignId,
  teamId,
  open,
  onClose,
  search,
  onSearchChange,
  formCurrency,
}) => {
  const { isoLocale } = useLocaleContext();

  const [internalSearch, setInternalSearch] = useState(search);

  useEffect(() => {
    if (!open) {
      setInternalSearch(search);
    }
  }, [search, open]);

  const handleOnSearchChange = useCallback(
    (search: string) => {
      setInternalSearch(search);
      onSearchChange(search);
    },
    [onSearchChange]
  );

  const { isFetching, isFetchingNextPage, data, hasNextPage, fetchNextPage } =
    trpc.getTeamMemberCampaigns.useInfiniteQuery(
      {
        teamId,
        limit: 10,
        search,
      },
      {
        enabled: open,
        initialCursor: 0,
        getNextPageParam: (lastPage) => lastPage?.nextCursor,
      }
    );

  const {
    data: searchedCampaignsData,
    isFetching: isSearchedCampaignsFetching,
    isFetchingNextPage: isSearchedCampaignsFetchingNextPage,
    hasNextPage: hasMoreSearchedCampaigns,
    fetchNextPage: fetchMoreSearchedCampaigns,
  } = trpc.searchPeerToPeerCampaigns.useInfiniteQuery(
    {
      campaignId,
      limit: 10,
      search,
      teamId,
    },
    {
      enabled: open && !!search,
      initialCursor: 0,
      getNextPageParam: (lastPage) => lastPage?.nextCursor,
    }
  );

  const individualCampaigns =
    data?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path: x.path }).path,
        currency: formCurrency,
      })) ?? [];

  const searchedCampaigns =
    searchedCampaignsData?.pages
      ?.filter((x) => !!x)
      ?.map((x) => x!.items)
      ?.flat()
      ?.filter((x) => x.type !== "team")
      ?.map((x) => ({
        ...x,
        href: RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({ isoLocale, path: x.path }).path,
        currency: formCurrency,
      })) ?? [];

  return (
    <StatelessTeamMemberCampaignsInformationDialog
      open={open}
      onClose={onClose}
      isLoading={
        (isFetching && !isFetchingNextPage) ||
        internalSearch !== search ||
        (isSearchedCampaignsFetching && !isSearchedCampaignsFetchingNextPage)
      }
      individualCampaigns={individualCampaigns}
      hasMoreIndividualCampaigns={hasNextPage ?? false}
      onFetchMoreIndividualCampaigns={fetchNextPage}
      searchedCampaigns={searchedCampaigns}
      hasMoreSearchedCampaigns={hasMoreSearchedCampaigns ?? false}
      onFetchMoreSearchedCampaigns={fetchMoreSearchedCampaigns}
      search={internalSearch}
      onSearchChange={handleOnSearchChange}
    />
  );
};

type StatelessTeamMemberCampaignsInformationDialogProps = Pick<InformationDialogProps, "open" | "onClose"> & {
  isLoading?: boolean;
  individualCampaigns: IndividualFundraiserProps[];
  hasMoreIndividualCampaigns: boolean;
  onFetchMoreIndividualCampaigns: () => void;
  searchedCampaigns: IndividualFundraiserProps[];
  hasMoreSearchedCampaigns: boolean;
  onFetchMoreSearchedCampaigns: () => void;
  search: string;
  onSearchChange: (search: string) => void;
};

export const StatelessTeamMemberCampaignsInformationDialog: FC<StatelessTeamMemberCampaignsInformationDialogProps> = ({
  isLoading = false,
  individualCampaigns,
  open,
  onClose,
  hasMoreIndividualCampaigns,
  onFetchMoreIndividualCampaigns,
  searchedCampaigns,
  hasMoreSearchedCampaigns,
  onFetchMoreSearchedCampaigns,
  search,
  onSearchChange,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();
  const { isSmallScreen } = useMediaQuery();

  const renderSearchContent = () => {
    const infiniteScrollLoader = (
      <Stack gap={1} marginBlockStart={1}>
        {Array.from({ length: 3 }).map((_, index) => (
          <FundraiserSkeleton key={index} />
        ))}
      </Stack>
    );

    return (
      <InfiniteScroll
        dataLength={searchedCampaigns.length}
        next={onFetchMoreSearchedCampaigns}
        hasMore={hasMoreSearchedCampaigns}
        loader={infiniteScrollLoader}
        height={isSmallScreen ? "70dvh" : 460}
        style={{ padding: theme.spacing(3) }}
      >
        <Stack gap={3}>
          <SearchField
            value={search}
            onChange={onSearchChange}
            placeholder={t("donationForm", "peerToPeer.fundraisersDialog.searchPlaceholder")}
          />
          <Stack gap={1}>
            {isLoading ? (
              Array.from({ length: 6 }).map((_, index) => <FundraiserSkeleton key={index} />)
            ) : searchedCampaigns.length > 0 ? (
              searchedCampaigns.map(({ ...campaign }, index) => (
                <IndividualFundraiser key={index} {...(campaign as IndividualFundraiserProps)} />
              ))
            ) : (
              <Typography variant="body2" vibe="text-form-quiet">
                <Trans
                  space="donationForm"
                  i18nKey="peerToPeer.fundraisers.searchNoResults"
                  components={{ sb: <Typography variant="subtitle2" vibe="text-form-quiet" component="span" /> }}
                />
              </Typography>
            )}
          </Stack>
        </Stack>
      </InfiniteScroll>
    );
  };

  const renderTeamMemberCampaigns = () => {
    const renderInfiniteScrollLoader = () => {
      return (
        <Stack gap={1} marginBlockStart={2}>
          {Array.from({ length: 4 }).map((_, index) => (
            <FundraiserSkeleton key={index} />
          ))}
        </Stack>
      );
    };

    return (
      <InfiniteScroll
        dataLength={individualCampaigns.length}
        next={onFetchMoreIndividualCampaigns}
        hasMore={hasMoreIndividualCampaigns}
        loader={renderInfiniteScrollLoader()}
        height={isSmallScreen ? "70dvh" : 460}
        style={{ padding: theme.spacing(3) }}
      >
        <Stack gap={3}>
          <SearchField
            value={search}
            onChange={onSearchChange}
            placeholder={t("donationForm", "peerToPeer.teamMemberCampaignsCard.searchPlaceholder")}
          />
          <Stack gap={1}>
            {isLoading
              ? Array.from({ length: 7 }).map((_, index) => <FundraiserSkeleton key={index} />)
              : individualCampaigns.map((campaign, index) => <IndividualFundraiser key={index} {...campaign} />)}
          </Stack>
        </Stack>
      </InfiniteScroll>
    );
  };

  const title = () => {
    return <Typography variant="h6">{t("donationForm", "peerToPeer.teamMemberCampaigns.title")}</Typography>;
  };

  return (
    <InformationDialog title={title()} open={open} onClose={onClose} noPadding>
      {search ? renderSearchContent() : renderTeamMemberCampaigns()}
    </InformationDialog>
  );
};
