import { FC } from "react";

import { getIsoLocaleFromCountry, getFormLink } from "@simplyk/common";

import { useDonationFormTranslationField } from "../../hooks/useDonationTranslation";

import { ZeffyHead } from "./ZeffyHead";

import { DonationFormOutput } from "@/types/trpc";

interface DonationFormHeadProps {
  donationForm: DonationFormOutput;
}

export const DonationFormHead: FC<DonationFormHeadProps> = ({ donationForm }) => {
  const { donationFormField } = useDonationFormTranslationField(donationForm);
  const frontendUrl = process.env.NEXT_PUBLIC_FRONTEND_URL as string;
  const url = donationFormField
    ? `${frontendUrl}${getFormLink(donationForm, getIsoLocaleFromCountry(undefined, donationFormField.locale))}`
    : undefined;

  return (
    <ZeffyHead
      description={donationFormField?.description}
      image={donationForm.bannerUrl || donationForm.logoUrl || undefined}
      title={donationFormField?.title}
      url={url}
      video={donationForm.bannerVideoUrl || undefined}
    />
  );
};
