import { FC } from "react";

import { Stack, useTheme } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const FundraiserSkeleton: FC = () => {
  const theme = useTheme();

  return (
    <Stack direction="row" gap={1.5} alignItems="center" padding={theme.spacing(0.5, 1)}>
      <Skeleton variant="rectangular" width={24} height={24} sx={{ flexShrink: 0, borderRadius: 0.375 }} />
      <Stack flexGrow={1} minWidth={0}>
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="40%" height={16} />
      </Stack>
    </Stack>
  );
};
