/* eslint-disable sonarjs/no-duplicate-string */
import { useContext, useState, useMemo, useCallback } from "react";

import { RouteBuilders, CampaignAction } from "@simplyk/common";
import router from "next/router";
import { useSnackbar } from "notistack";
import { useForm, useWatch } from "react-hook-form";

import { TeamData, TeamWithTeamId, TeamWithTeamName } from "../../../../../components/CampaignModal/teamData";
import { CampaignModalProps } from "../../../../../components/CampaignModal/type";
import { FrontendDonationFormContext } from "../../../../../contexts/FrontendDonationFormContext";
import { FrontendFormContext } from "../../../../../contexts/FrontendFormContext";
import { useLocaleContext } from "../../../../../contexts/LocaleContext";
import { trpc } from "../../../../../helpers/trpc";
import { useTranslate } from "../../../../../hooks/useTranslate";
import { FRONTEND_URL } from "../../../../../routes/routes";

import { useFundraiser } from "@/hooks/useFundraiser";

export const useFundraiseDialog = ({ campaignId, defaultAction }: CampaignModalProps) => {
  const { donationForm } = useContext(FrontendDonationFormContext);
  const { organization } = useContext(FrontendFormContext);
  const organizationCountry = organization?.country;

  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { campaignAction, hasTeam, toggleCampaignAction, defaultTarget } = useFundraiser({
    allowFundraiserCreation: Boolean(
      donationForm.isPrimaryCampaign
        ? donationForm.allowFundraiserCreation
        : donationForm.campaign?.allowFundraiserCreation
    ),
    allowTeamCreation: Boolean(
      donationForm.isPrimaryCampaign ? donationForm.allowTeamCreation : donationForm.campaign?.allowTeamCreation
    ),
    campaignId,
    defaultAction,
  });

  const { mutateAsync: createTeam, isLoading: loadingCreateTeam } = trpc.createTeam.useMutation();
  const { mutateAsync: joinTeam, isLoading: loadingJoinTeam } = trpc.joinTeam.useMutation();
  const { mutateAsync: createIndividualFundraising, isLoading: loadingIndividualFundraising } =
    trpc.createIndividualFundraising.useMutation();

  const { t } = useTranslate();
  const { isoLocale } = useLocaleContext();
  const form = useForm<TeamData>({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      campaignTarget: defaultTarget,
      joinTeamCreated: true,
    },
    shouldUnregister: false,
  });
  const { locale } = useLocaleContext();

  const isCreateTeam = useCallback((data: TeamData, campaignAction: CampaignAction): data is TeamWithTeamName => {
    return campaignAction === CampaignAction.CreateTeam;
  }, []);

  const isJoinTeam = useCallback((data: TeamData, campaignAction: CampaignAction): data is TeamWithTeamId => {
    return campaignAction === CampaignAction.JoinTeam;
  }, []);

  const isCreateIndividualFundraising = useCallback(
    (data: TeamData, campaignAction: CampaignAction): data is TeamData => {
      return campaignAction === CampaignAction.IndividualFundraising;
    },
    []
  );

  const errorOnFormCreation = useCallback(
    () => enqueueSnackbar(t("donationForm", "campaignPeerToPeerError"), { vibe: "danger" }),
    [enqueueSnackbar, t]
  );

  const onSubmit = form.handleSubmit(async (data: TeamData) => {
    setIsLoading(true);
    if (!organizationCountry) {
      return;
    }

    if (isCreateTeam(data, campaignAction)) {
      const { data: duplicatedDonationForm, error } = await createTeam({
        teamInput: {
          name: data.teamName,
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          locale,
          logo: data.logo || null,
          target: data.campaignTarget,
          organizationCountry,
          joinTeamCreated: data.joinTeamCreated,
          teamToJoinId: null,
          campaignId,
        },
        campaignId,
      });

      if (error || !duplicatedDonationForm) {
        const code = error?.code || "unknown_error";
        const displayedMessage = t("common", code as never);
        return { error: { message: displayedMessage, code: code || "" } };
      }

      // Save token and formId in local storage
      localStorage.setItem(
        "peer-to-peer-form-data",
        JSON.stringify({
          token: duplicatedDonationForm.token,
          formId: duplicatedDonationForm.id,
        })
      );

      const formLink = `${FRONTEND_URL}${
        RouteBuilders.buildPeerToPeerV2TeamCampaignLink({
          isoLocale,
          path: duplicatedDonationForm?.path,
        }).path
      }?new=true`;

      if (!error && duplicatedDonationForm.id) {
        router.push(formLink);
      } else {
        errorOnFormCreation();
      }
    }
    if (isJoinTeam(data, campaignAction)) {
      const { data: duplicatedDonationForm, error } = await joinTeam({
        teamInput: {
          name: `${data.firstName} ${data.lastName}`,
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          locale,
          logo: data.logo || null,
          target: data.campaignTarget,
          organizationCountry,
          campaignId: null,
          teamToJoinId: data.team,
        },
        teamId: data.team,
      });

      if (error || !duplicatedDonationForm) {
        const code = error?.code || "unknown_error";
        const displayedMessage = t("common", code as never);
        return { error: { message: displayedMessage, code: code || "" } };
      }

      // Save token and formId in local storage
      localStorage.setItem(
        "peer-to-peer-form-data",
        JSON.stringify({
          token: duplicatedDonationForm.token,
          formId: duplicatedDonationForm.id,
        })
      );

      const formLink = `${FRONTEND_URL}${
        RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({
          isoLocale,
          path: duplicatedDonationForm?.path,
        }).path
      }?new=true`;

      if (!error && duplicatedDonationForm.id) {
        router.push(formLink);
      } else {
        errorOnFormCreation();
      }
    }
    if (isCreateIndividualFundraising(data, campaignAction)) {
      const { data: duplicatedDonationForm, error } = await createIndividualFundraising({
        fundraisingInput: {
          name: `${data.firstName} ${data.lastName}`,
          lastName: data.lastName,
          firstName: data.firstName,
          email: data.email,
          locale,
          logo: data.logo || null,
          target: data.campaignTarget,
          organizationCountry,
          campaignId,
          teamToJoinId: null,
        },
        campaignId,
      });

      if (error || !duplicatedDonationForm) {
        const code = error?.code || "unknown_error";
        const displayedMessage = t("common", code as never);
        return { error: { message: displayedMessage, code: code || "" } };
      }

      // Save token and formId in local storage
      localStorage.setItem(
        "peer-to-peer-form-data",
        JSON.stringify({
          token: duplicatedDonationForm.token,
          formId: duplicatedDonationForm.id,
        })
      );

      const formLink = `${FRONTEND_URL}${
        RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({
          isoLocale,
          path: duplicatedDonationForm?.path,
        }).path
      }?new=true`;

      if (!error && duplicatedDonationForm.id) {
        router.push(formLink);
      } else {
        errorOnFormCreation();
      }
    }
    setIsLoading(false);
  });

  const watchedTeamName = useWatch({ control: form.control, name: "teamName" });
  const watchedTeam = useWatch({ control: form.control, name: "team" });
  const watchedLastName = useWatch({ control: form.control, name: "lastName" });
  const watchedFirstName = useWatch({ control: form.control, name: "firstName" });
  const watchedEmail = useWatch({ control: form.control, name: "email" });
  const watchedTarget = useWatch({ control: form.control, name: "campaignTarget" });

  const missingTeamName = useMemo(
    () =>
      (campaignAction === CampaignAction.CreateTeam && !watchedTeamName) ||
      (campaignAction === CampaignAction.JoinTeam && !watchedTeam),
    [campaignAction, watchedTeam, watchedTeamName]
  );

  const submitIsDisabled = useMemo(
    () =>
      !watchedLastName ||
      !watchedFirstName ||
      !watchedEmail ||
      !watchedTarget ||
      missingTeamName ||
      donationForm.isArchived,
    [donationForm.isArchived, missingTeamName, watchedEmail, watchedFirstName, watchedLastName, watchedTarget]
  );

  return {
    campaignAction,
    form,
    hasTeam,
    onSubmit,
    toggleCampaignAction,
    loading: loadingJoinTeam || loadingCreateTeam || loadingIndividualFundraising || isLoading,
    submitIsDisabled,
    organizationCountry,
    defaultTarget,
  };
};
