import { useEffect, useState } from "react";

import { RouteBuilders } from "@simplyk/common";
import { useRouter } from "next/router";

import { useLocaleContext } from "../../../../../contexts/LocaleContext";
import { useFormV2Context } from "../../../context/FormV2Context";

export const useCampaignCreatedSuccess = (isP2PFundraiserPage: boolean) => {
  const { formObject } = useFormV2Context();
  const { isoLocale } = useLocaleContext();
  const frontendUrl = process.env.NEXT_PUBLIC_FRONTEND_URL as string;
  const router = useRouter();
  const { new: isNew } = router.query;

  const [isCampaignIsLiveDialogOpen, setCampaignIsLiveDialogOpen] = useState(false);

  const formLink = isP2PFundraiserPage
    ? `${frontendUrl}${
        RouteBuilders.buildPeerToPeerV2IndividualCampaignLink({
          isoLocale,
          path: formObject?.path,
        }).path
      }`
    : `${frontendUrl}${
        RouteBuilders.buildPeerToPeerV2TeamCampaignLink({
          isoLocale,
          path: formObject?.path,
        }).path
      }`;

  useEffect(() => {
    if (isNew) {
      setCampaignIsLiveDialogOpen(true);
    }
  }, [isNew]);

  const handleCampaignIsLiveDialogClose = () => {
    setCampaignIsLiveDialogOpen(false);
    const { pathname, query } = router;
    const { new: _new, ...rest } = query;
    router.push(
      {
        pathname,
        query: rest,
      },
      undefined,
      { shallow: true }
    );
  };

  return {
    isCampaignIsLiveDialogOpen,
    handleCampaignIsLiveDialogClose,
    formLink,
  };
};
