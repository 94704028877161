import { FC } from "react";

import { getField, RouteBuilders } from "@simplyk/common";

import { useLocaleContext } from "../../../../../../contexts/LocaleContext";
import { useGetCampaignWithVolumeQuery } from "../../../../../../gql/queries/generated/campaignQuery";
import { useGetTeamWithVolumeQuery } from "../../../../../../gql/queries/generated/teamQuery";
import { useFormV2Context } from "../../../../context/FormV2Context";
import { GeneralFundraiser } from "../../../PeerToPeer/GeneralFundraiser";
import { TeamFundraiser } from "../../../PeerToPeer/TeamFundraiser";

import { StatelessHostedBy } from "./StatelessHostedBy";

interface HostedByProps {
  isIndividualCampaignInTeamCampaign?: boolean;
  campaignId: string;
  teamId?: string;
}

export const HostedBy: FC<HostedByProps> = ({ isIndividualCampaignInTeamCampaign = false, campaignId, teamId }) => {
  const { locale, isoLocale } = useLocaleContext();

  const { formCurrency } = useFormV2Context();

  // Get campaign data information
  const { data: campaignData } = useGetCampaignWithVolumeQuery({
    variables: { campaignId: campaignId as string },
  });

  const campaignObject = campaignData?.getCampaignWithVolume?.object;
  const campaignLogoUrl = campaignData?.getCampaignWithVolume?.object?.logoUrl || undefined;
  const campaignTitle = getField(campaignData?.getCampaignWithVolume?.object?.donationFormFields, locale)?.title || "";
  const campaignOrganizationName = campaignData?.getCampaignWithVolume?.object?.organization?.name || "";

  // Get team data information
  const { data: teamData } = useGetTeamWithVolumeQuery({
    variables: { teamId: teamId as string },
    skip: !teamId,
  });

  const teamObject = teamData?.getTeamWithVolume?.object;
  const teamLogoUrl = teamObject?.logoUrl || undefined;
  const teamTitle = getField(teamObject?.donationFormFields, locale)?.title || "";
  const teamVolume = teamObject?.formVolume || 0;

  if (!campaignObject) {
    return null;
  }

  if (teamId && !teamObject) {
    return null;
  }

  return (
    <StatelessHostedBy
      slots={{
        generalFundraiser: (
          <GeneralFundraiser
            href={RouteBuilders.buildPeerToPeerV2GeneralCampaignLink({ isoLocale, path: campaignObject!.path }).path}
            imageSrc={campaignLogoUrl || null}
            organizationName={campaignOrganizationName}
            campaignTitle={campaignTitle}
            currency={formCurrency}
          />
        ),
        teamFundraiser: isIndividualCampaignInTeamCampaign ? (
          <TeamFundraiser
            href={RouteBuilders.buildPeerToPeerV2TeamCampaignLink({ isoLocale, path: teamObject!.path }).path}
            imageSrc={teamLogoUrl || null}
            campaignTitle={teamTitle}
            totalRaised={teamVolume}
            currency={formCurrency}
          />
        ) : null,
      }}
    />
  );
};
