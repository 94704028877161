import { useContext, useMemo } from "react";

import { CommandStatus, FormSubmissionStatus } from "@simplyk/common";
import { DateTime } from "luxon";
import { Control, useFormState, useWatch } from "react-hook-form";

import { getProductDonationRecurrenceFromStripe } from "../components/PaymentProcessor/helper";
import { FrontendFormContext } from "../contexts/FrontendFormContext";
import { useLocaleContext } from "../contexts/LocaleContext";
import { CommandLang, CommandSource } from "../gql/gql-types";

import { useUpsertCommand } from "./useUpsertCommand";
import { useUpsertVisitorTracking } from "./useUpsertVisitorTracking";

import { convertCountryToCountryCode } from "@/helpers/command";
import { DonationFormPaymentInput } from "@/types/donationForm";

interface DonationFormUpdatedProps {
  control: Control<DonationFormPaymentInput>;
}

export const useDonationFormUpdated = ({ control }: DonationFormUpdatedProps) => {
  const { isDirty, isSubmitting, isSubmitSuccessful } = useFormState({ control });
  const { locale } = useLocaleContext();
  const {
    tip,
    displayedFormAmount,
    selectedPaymentMethod,
    stripeRecurrenceInterval,
    formData,
    formType,
    organization,
    category,
  } = useContext(FrontendFormContext);

  const email = useWatch({ control, name: "email" });
  const firstName = useWatch({ control, name: "firstName" });
  const lastName = useWatch({ control, name: "lastName" });
  const address = useWatch({ control, name: "address" });
  const city = useWatch({ control, name: "city" });
  const postalCode = useWatch({ control, name: "postalCode" });
  const region = useWatch({ control, name: "region" });
  const formCountry = useWatch({ control, name: "country" });
  const corporationName = useWatch({ control, name: "companyName" });
  const isCorporate = useWatch({ control, name: "giveAsOrganism" });
  const isPublicDonation = useWatch({ control, name: "isPublicDonation" }) ?? false;
  const isInHonour = useWatch({ control, name: "inHonourOf" }) ?? false;
  const inHonourName = useWatch({ control, name: "inHonourName" });
  const sendInHonourEmail = useWatch({ control, name: "notifySomeone" }) ?? false;
  const inHonourEmailAddress = useWatch({ control, name: "inHonourNotifyEmail" });
  const inHonourEmailBody = useWatch({ control, name: "inHonourMessage" });

  const country = useMemo(() => convertCountryToCountryCode(formCountry), [formCountry]);

  useUpsertVisitorTracking({
    props: {
      formId: formData.id,
      organizationId: organization.id,
      formSubmissionStatus: FormSubmissionStatus.Incomplete,
      firstName,
      lastName,
      email,
      formCategory: category,
    },
    isDirty,
    shouldCancel: isSubmitting || isSubmitSuccessful,
  });

  useUpsertCommand(
    {
      email,
      firstName,
      lastName,
      address,
      city,
      postalCode,
      region,
      country,
      isCorporate,
      corporationName,
      productsAmount: displayedFormAmount ?? 0,
      totalAmount: (displayedFormAmount ?? 0) + (tip ?? 0),
      eligibleAmount: displayedFormAmount,
      tipAmount: tip,
      tipPercentage: 0,
      paymentMethod: selectedPaymentMethod,
      productDonation: {
        amount: displayedFormAmount,
        isAnonymous: !isPublicDonation,
        isInHonour,
        inHonourName,
        sendInHonourEmail,
        inHonourEmailAddress,
        inHonourEmailBody,
        recurrenceInterval: getProductDonationRecurrenceFromStripe(stripeRecurrenceInterval),
      },
      productTickets: null,
      productBids: null,
      formId: formData.id,
      formLang: CommandLang[locale],
      formType: formType || null,
      locale,
      organizationId: organization.id,
      source: CommandSource.FormSubmission,
      status: CommandStatus.Open,
      userTimezone: DateTime.local().zoneName || "UTC",
      isFormV2: false,
    },
    isDirty,
    isSubmitting || isSubmitSuccessful
  );
};
