import * as React from "react";

import { MembershipValidityPeriod, Nullable } from "@simplyk/common";
import { UseFormSetValue } from "react-hook-form";

import { Event } from "../components/PaymentProcessor/type";
import {
  DiscountObject,
  OrganizationObject,
  OccurrenceWithRatesObject,
  TicketingDonationFormObject,
  TicketingFeatureFlagObject,
} from "../gql/gql-types";

import { TicketingPaymentInput } from "@/types/ticketing";
import { QuestionOutput, TicketingOutput, TicketingFieldOutput } from "@/types/trpc";

export interface FullRateError {
  id: string;
  remainingTickets: number;
}

export interface MembershipRenewalBuyerInfo {
  expirationDate: Date | undefined;
  uniquelySelectedTicketIndex: number;
  shouldProposeAutomaticRenewal: boolean | undefined;
  validityPeriod: MembershipValidityPeriod | null;
}

export interface FrontendTicketingContextValues {
  ticketingIsFull: boolean;
  ticketingIsClosed: boolean;
  ticketingDescriptionIsOpen: boolean;
  openTicketingDescription: () => void;
  closeTicketingDescription: () => void;
  buyerDrawerIsOpen: boolean;
  toggleBuyerDrawer: (_setValue: UseFormSetValue<TicketingPaymentInput>) => () => void;
  fallBackToTicketingContainerOnFullTicketing: (_setValue: UseFormSetValue<TicketingPaymentInput>) => void;
  fallBackToTicketsDrawerOnOverpassedRatesSeats: (
    _fullRates: FullRateError[],
    _setValue: UseFormSetValue<TicketingPaymentInput>
  ) => void;
  fallBackToTicketsDrawerOnOverPassedTicketingSeats: (
    _remainingTicketingSeats: number,
    _setValue: UseFormSetValue<TicketingPaymentInput>
  ) => void;
  fallBackToTicketingContainerOnNoTicketSelected: (_setValue: UseFormSetValue<TicketingPaymentInput>) => void;
  fullRatesReturnByOrder: FullRateError[];
  remainingTicketingSeatsReturnByOrder: number | null;
  remainingTicketingSeats: number;
  hasDiscountCodes: boolean;
  validDiscount: DiscountObject | null;
  setValidDiscount: (_discountCode: DiscountObject | null) => void;
  ticketing: TicketingOutput;
  ticketingField: TicketingFieldOutput | undefined;
  organization: OrganizationObject;
  questions: QuestionOutput[];
  featureFlag: TicketingFeatureFlagObject;
  registrationIncreaseThermometer?: boolean;
  isOnboarding: boolean;
  setSelectedOccurrenceId: (_occurrenceId: string | null) => void;
  selectedOccurrenceId: Nullable<string>;
  selectedOccurrenceWithRates: Nullable<OccurrenceWithRatesObject>;
  occurrencesWithRates: OccurrenceWithRatesObject[];
  hasADate: boolean;
  isOccurrencesWithRatesLoading: boolean;
  hasOnlyOneDate: boolean;
  isCampaignRegistration: boolean;
  donationForm: TicketingDonationFormObject | undefined;
  event: Event | undefined;
  isAuction: boolean;
  membershipRenewalBuyerInfo: MembershipRenewalBuyerInfo | undefined;
  setMembershipRenewalBuyerInfo: (membershipRenewalBuyerInfo: MembershipRenewalBuyerInfo | undefined) => void;
}

const defaultValues: FrontendTicketingContextValues = {
  ticketingIsFull: false,
  ticketingIsClosed: false,
  ticketingDescriptionIsOpen: false,
  openTicketingDescription: () => {},
  closeTicketingDescription: () => {},
  buyerDrawerIsOpen: false,
  toggleBuyerDrawer: (_setValue: UseFormSetValue<TicketingPaymentInput>) => () => {},
  fallBackToTicketingContainerOnFullTicketing: (_setValue: UseFormSetValue<TicketingPaymentInput>) => {},
  fallBackToTicketsDrawerOnOverpassedRatesSeats: (
    _fullRates: FullRateError[],
    _setValue: UseFormSetValue<TicketingPaymentInput>
  ) => {},
  fallBackToTicketsDrawerOnOverPassedTicketingSeats: (
    _remainingTicketingSeats: number,
    _setValue: UseFormSetValue<TicketingPaymentInput>
  ) => {},
  fallBackToTicketingContainerOnNoTicketSelected: (_setValue: UseFormSetValue<TicketingPaymentInput>) => {},
  fullRatesReturnByOrder: [] as FullRateError[],
  remainingTicketingSeatsReturnByOrder: null as number | null,
  remainingTicketingSeats: Infinity,
  hasDiscountCodes: false,
  validDiscount: null as DiscountObject | null,
  setValidDiscount: (_discountCode: DiscountObject | null) => {},
  ticketing: {} as TicketingOutput,
  ticketingField: {} as TicketingFieldOutput,
  organization: {} as OrganizationObject,
  questions: [] as QuestionOutput[],
  featureFlag: {} as TicketingFeatureFlagObject,
  registrationIncreaseThermometer: false,
  isOnboarding: false,
  selectedOccurrenceWithRates: null,
  occurrencesWithRates: [],
  hasADate: false,
  isOccurrencesWithRatesLoading: true,
  setSelectedOccurrenceId: (_occurrenceId: string | null) => {},
  selectedOccurrenceId: null,
  hasOnlyOneDate: false,
  isCampaignRegistration: false,
  donationForm: undefined,
  event: undefined,
  isAuction: false,
  membershipRenewalBuyerInfo: undefined,
  setMembershipRenewalBuyerInfo: () => {},
};
export const FrontendTicketingContext = React.createContext<FrontendTicketingContextValues>(defaultValues);
