export enum RecaptchaAction {
  IsEmailRegistered = "IsEmailRegistered",
  CreateDonorFree = "CreateDonorFree",
  CreateDonor = "CreateDonor",
  ConfirmPaymentIntent = "ConfirmPaymentIntent",
  TicketingCreateCardPaymentIntent = "TicketingCreateCardPaymentIntent",
  TicketingCreatePadOrAchPaymentIntent = "TicketingCreatePadOrAchPaymentIntent",
  TicketingConfirmPaymentIntent = "TicketingConfirmPaymentIntent",
  DonationFormCreateCardPaymentIntent = "DonationFormCreateCardPaymentIntent",
  DonationFormCreatePadOrAchPaymentIntent = "DonationFormCreatePadOrAchPaymentIntent",
  DonationFormConfirmPaymentIntent = "DonationFormConfirmPaymentIntent",
  SignUp = "SignUp",
  SubmitCommand = "SubmitCommand",
  GoogleSSO = "GoogleSSO",
  UpdateDonationFormAmounts = "UpdateDonationFormAmounts",
  PostSubmitCommand = "PostSubmitCommand",
  SendSmsOtp = "SendSmsOtp",
  SendSmsOtpToSignin = "SendSmsOtpToSignin",
  SignIn = "SignIn",
  SignInAdmin = "SignInAdmin",
  SwitchOrgAdmin = "SwitchOrgAdmin",
  ResendTransactionReceipt = "ResendTransactionReceipt",
  SendResetPasswordRequest = "SendResetPasswordRequest",
  TranslateText = "TranslateText",
}
