import { FC, useContext } from "react";

import { FormCategory, PaymentMethod } from "@simplyk/common";

import { FormType } from "../../gql/gql-types";

import { FrontendDonationFormContext } from "@/contexts/FrontendDonationFormContext";
import { FrontendFormContext } from "@/contexts/FrontendFormContext";
import { FormV2Layout } from "@/features/FormV2/FormV2Layout";
import { FormV2Providers } from "@/features/FormV2/FormV2Providers";
import { CloseEmbedButton } from "@/features/FormV2/sharedComponents/CloseEmbedButton";
import { FormV2LanguageSelect } from "@/features/FormV2/sharedComponents/FormV2LanguageSelect";
import { DonationFormThankYouLoader } from "@/features/FormV2/sharedComponents/ThankYouLoaderContainer";
import { DonationFormV2Step1 } from "@/features/FormV2/Step1/DonationFormV2Step1";
import { PeerToPeerFormV2IndividualCampaignStep1 } from "@/features/FormV2/Step1/PeerToPeerFormV2IndividualCampaignStep1";
import { PeerToPeerFormV2PrimaryCampaignStep1 } from "@/features/FormV2/Step1/PeerToPeerFormV2PrimaryCampaignStep1";
import { PeerToPeerFormV2TeamCampaignStep1 } from "@/features/FormV2/Step1/PeerToPeerFormV2TeamCampaignStep1";
import { FormV2Step2 } from "@/features/FormV2/Step2/FormV2Step2";
import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";

export type DonationFormV2PageProps = {
  isEmbed?: boolean;
};

export const DonationFormV2Page: FC<DonationFormV2PageProps> = ({ isEmbed = false }) => {
  const { donationForm } = useContext(FrontendDonationFormContext);

  const { isPreview } = usePreviewContext();
  const { isSubmitting, selectedPaymentMethod } = useContext(FrontendFormContext);

  const displayThankYou = !(selectedPaymentMethod === PaymentMethod.CashApp || !isSubmitting);

  const renderSelectionStep = () => {
    if (donationForm.category === FormCategory.PeerToPeerCampaign) {
      if (donationForm.isPrimaryCampaign) {
        return <PeerToPeerFormV2PrimaryCampaignStep1 isEmbed={isEmbed} />;
      }

      if (donationForm.isPrimaryTeam) {
        return <PeerToPeerFormV2TeamCampaignStep1 isEmbed={isEmbed} />;
      }

      return <PeerToPeerFormV2IndividualCampaignStep1 isEmbed={isEmbed} />;
    }

    return <DonationFormV2Step1 isEmbed={isEmbed} />;
  };

  return (
    <FormV2Providers type={FormType.DonationForm} formObject={donationForm}>
      {(currentStep) => (
        <FormV2Layout
          step={currentStep}
          slots={{
            languageSelect: <FormV2LanguageSelect />,
            closeEmbedButton: <CloseEmbedButton />,
            selectionStep: renderSelectionStep(),
            paymentStep: <FormV2Step2 />,
            thankYou: displayThankYou ? <DonationFormThankYouLoader /> : undefined,
          }}
          preloadSteps={!isPreview}
        />
      )}
    </FormV2Providers>
  );
};
