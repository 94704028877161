/* eslint-disable sonarjs/no-duplicate-string */
import { FC, useContext } from "react";

import { FormType } from "@simplyk/common";
import { useQueryState, parseAsBoolean } from "nuqs";

import { useTranslate } from "../../../hooks/useTranslate";
import { useFormV2Context } from "../context/FormV2Context";
import { HeartsAnimationProvider } from "../context/heartsAnimationContext";

import { DonationFormV2CheckoutFooter } from "./CheckoutFooter/DonationFormV2CheckoutFooter";
import { PeerToPeerDonateOrFundraiseOption } from "./DonationForm/PeerToPeer/PeerToPeerDonateOrFundraiseOption";
import { CampaignIsLiveDialog } from "./PeerToPeer/CampaignIsLiveDialog/CampaignIsLiveDialog";
import { useCampaignCreatedSuccess } from "./PeerToPeer/CampaignIsLiveDialog/useCampaignCreatedSuccess";
import { FundraiseDialog } from "./PeerToPeer/FundraiseDialog/FundraiseDialog";
import { VersionControlAlertV2 } from "./VersionControlAlertV2";

import { NotificationBannerHeightContext } from "@/components/NotificationBanners/NotificationBannerHeightContext";
import { useUtmForm } from "@/features/FormV2/hooks/useUtmForm";
import { Amounts } from "@/features/FormV2/Step1/DonationForm/Amounts";
import { HostedBy } from "@/features/FormV2/Step1/DonationForm/PeerToPeer/HostedBy/HostedBy";
import { useDonationFirstStepSubmit } from "@/features/FormV2/Step1/DonationForm/useDonationFirststepSubmit";
import { FormV2Banner } from "@/features/FormV2/Step1/FormV2Banner";
import { FormV2Summary } from "@/features/FormV2/Step1/FormV2Summary";
import { PeerToPeerFormV2IndividualCampaignStep1Layout } from "@/features/FormV2/Step1/PeerToPeerFormV2IndividualCampaignStep1Layout";
import { usePreviewContext } from "@/features/LiveFormEditor/LivePreview/context/PreviewContext";

export type PeerToPeerFormV2IndividualCampaignStep1Props = {
  isEmbed?: boolean;
};

export const PeerToPeerFormV2IndividualCampaignStep1: FC<PeerToPeerFormV2IndividualCampaignStep1Props> = ({
  isEmbed = false,
}) => {
  const { t } = useTranslate();
  const { formObject, hasBanner } = useFormV2Context(FormType.DonationForm);
  const { isExpanded, bannerHeight } = useContext(NotificationBannerHeightContext);
  const { isPreview } = usePreviewContext();
  const { canSubmitFirstStep } = useDonationFirstStepSubmit();
  const utm = useUtmForm();

  const [fundraiseQuery, setFundraiseQuery] = useQueryState(
    "fundraise",
    parseAsBoolean.withDefault(false).withOptions({ history: "push" })
  );

  const { isCampaignIsLiveDialogOpen, handleCampaignIsLiveDialogClose, formLink } = useCampaignCreatedSuccess(false);

  return (
    <HeartsAnimationProvider>
      <PeerToPeerFormV2IndividualCampaignStep1Layout
        isEmbed={isEmbed}
        contextDrawerHeight={isExpanded ? bannerHeight : 0}
        slots={{
          banner: hasBanner ? <FormV2Banner /> : undefined,
          summary: <FormV2Summary hideLogoChip={true} hideBanner={true} forceInlined={isEmbed} />,
          hostedBy: (
            <HostedBy
              campaignId={formObject.campaignId!}
              teamId={formObject.teamId ?? undefined}
              isIndividualCampaignInTeamCampaign={!!formObject.teamId}
            />
          ),
          helpFundraise:
            formObject.campaign?.allowTeamCreation || formObject.campaign?.allowFundraiserCreation ? (
              <PeerToPeerDonateOrFundraiseOption
                title={t("donationForm", "peerToPeer.title.wantToFundraise")}
                description={t("donationForm", "peerToPeer.description.createCampaign")}
                buttonText={t("donationForm", "peerToPeer.button.wantToFundraise")}
                buttonVariant="outlined"
                onClick={() => setFundraiseQuery(true)}
                data-test="fundraise-button"
              />
            ) : undefined,
          donationAmounts: <Amounts />,
          versionControlAlert: <VersionControlAlertV2 />,
          checkoutFooter: <DonationFormV2CheckoutFooter />,
        }}
        showCheckoutFooter={canSubmitFirstStep && !isPreview}
        utm={utm}
      />
      <FundraiseDialog
        open={fundraiseQuery}
        onClose={() => setFundraiseQuery(false)}
        campaignId={formObject.campaignId as string}
        allowTeamCreation={Boolean(formObject.campaign?.allowTeamCreation)}
        allowFundraiserCreation={Boolean(formObject.campaign?.allowFundraiserCreation)}
      />
      {/* Display the campaign is live dialog if the p2p campaign is created successfully */}
      <CampaignIsLiveDialog
        open={isCampaignIsLiveDialogOpen}
        onClose={handleCampaignIsLiveDialogClose}
        isTeamCampaign={false}
        isIndividualCampaign={true}
        fundraiserName={formObject.donationFormFields?.[0]?.title}
        formLink={formLink}
      />
    </HeartsAnimationProvider>
  );
};
