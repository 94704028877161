import { FC, useCallback } from "react";

import { Box, Stack, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";

import { ButtonCopy } from "../../../../../components/ButtonCopy/ButtonCopy";
import { Button } from "../../../../../components/design-system/Button";
import { Icon } from "../../../../../components/design-system/Icon";
import { Typography } from "../../../../../components/design-system/Typography";
import { AutoHideDuration } from "../../../../../constants/Notistack";
import { useTranslate } from "../../../../../hooks/useTranslate";

import { InformationDialog, InformationDialogProps } from "@/design-system/Dialog/InformationDialog";
import { Success } from "@/icons/outlined";

export type CampaignIsLiveDialogProps = Pick<InformationDialogProps, "open" | "onClose"> & {
  isTeamCampaign?: boolean;
  isIndividualCampaign?: boolean;
  fundraiserName?: string;
  formLink: string;
};

export const CampaignIsLiveDialog: FC<CampaignIsLiveDialogProps> = ({
  open,
  onClose,
  isTeamCampaign,
  isIndividualCampaign,
  fundraiserName,
  formLink,
}) => {
  const { t } = useTranslate();
  const theme = useTheme();

  const onCopy = useCallback(() => {
    enqueueSnackbar(t("donationForm", "linkCopied"), {
      vibe: "positive",
      hideClose: true,
      autoHideDuration: AutoHideDuration.fast,
    });
  }, [t]);

  const title = isTeamCampaign
    ? t("donationForm", "campaignIsLiveDialog.title.team")
    : isIndividualCampaign
      ? t("donationForm", "campaignIsLiveDialog.title.individual", { fundraiserName })
      : "";

  const description = isTeamCampaign
    ? t("donationForm", "campaignIsLiveDialog.description.team")
    : isIndividualCampaign
      ? t("donationForm", "campaignIsLiveDialog.description.individual")
      : "";

  const iconHeader = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: 24,
          height: 24,
          borderRadius: 1,
          padding: 2,
          backgroundColor: theme.palette.surface.positive.quiet,
        }}
      >
        <Icon vibe="text-positive-intense">
          <Success />
        </Icon>
      </Box>
    );
  };

  const actionButtons = () => {
    return (
      <Stack
        direction={{ xs: "column", md: "row-reverse" }}
        justifyContent={{ md: "flex-start" }}
        spacing={1}
        sx={{ width: "100%", padding: 1 }}
      >
        <ButtonCopy
          variant="filled"
          vibe="positive"
          onClick={onCopy}
          toCopy={formLink}
          copyText={t("donationForm", "campaignIsLiveDialog.button.copyShareLink")}
        />
        <Button variant="outlined" vibe="neutral" onClick={onClose}>
          {t("donationForm", "campaignIsLiveDialog.button.viewCampaign")}
        </Button>
      </Stack>
    );
  };

  return (
    <InformationDialog
      title={iconHeader()}
      open={open}
      onClose={onClose}
      noPadding
      actions={actionButtons()}
      noDivider
      maxWidth="xs"
    >
      <Stack spacing={2} paddingX={3} paddingBottom={2}>
        <Typography variant="h6" vibe="text-neutral-intense" data-test="campaign-is-live-dialog-title">
          {title}
        </Typography>
        <Typography variant="body2" vibe="text-neutral-moderate">
          {description}
        </Typography>
      </Stack>
    </InformationDialog>
  );
};
