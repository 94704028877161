import { FC } from "react";

import { Fundraiser, FundraiserProps } from "./Fundraiser";

import { useTranslate } from "@/hooks/useTranslate";

export type TeamFundraiserProps = Omit<FundraiserProps, "title" | "subtitle"> & {
  campaignTitle: string;
  totalRaised: number;
};

export const TeamFundraiser: FC<TeamFundraiserProps> = ({ href, imageSrc, campaignTitle, totalRaised, currency }) => {
  const { t } = useTranslate();

  return (
    <Fundraiser
      href={href}
      imageSrc={imageSrc}
      title={campaignTitle}
      subtitle={t("donationForm", "peerToPeer.teamCampaignType")}
      totalRaised={totalRaised}
      currency={currency}
      showFallbackImage
    />
  );
};
