import React, { useState, useCallback, useMemo, useContext, useEffect } from "react";

import {
  FormType,
  getCurrencyFromCountry,
  OrganizationCountry,
  ProductDonationRecurrenceInterval,
} from "@simplyk/common";
import { useRouter } from "next/router";
import { Control, FormProvider, useForm, useWatch } from "react-hook-form";
import { v4 } from "uuid";

import { FrontendFormContext } from "../../../contexts/FrontendFormContext";
import { DonationFormAmountObject, OrganizationObject } from "../../../gql/gql-types";
import { useDonationFormUpdated } from "../../../hooks/useDonationFormUpdated";
import { DonationFormPaymentInput } from "../../../types/donationForm";
import { usePreviewContext } from "../../LiveFormEditor/LivePreview/context/PreviewContext";
import { getDefaultRecurrenceInterval } from "../helpers/getDefaultRecurrenceInterval";
import { useLocaleField } from "../helpers/useLocaleField";
import { FormV2StepKey } from "../types/FormV2StepKey";

import { DonationPaymentInput, DonationFormV2Context, FormV2Context } from "./FormV2Context";

import { QuestionOutput, DonationFormOutput } from "@/types/trpc";

export interface DonationFormV2ProviderProps {
  type: FormType.DonationForm;
  formObject: DonationFormOutput;
  currentStep: FormV2StepKey;
  setCurrentStep: (step: FormV2StepKey) => void;
  children: React.ReactNode;
}

export const DonationFormV2Provider: React.FC<DonationFormV2ProviderProps> = ({
  formObject,
  currentStep,
  setCurrentStep,
  children,
}) => {
  const router = useRouter();
  const { isPreview } = usePreviewContext();
  const [validationMode, setValidationMode] = useState<"onBlur" | "onChange">("onBlur");

  const getLocaleField = useLocaleField();
  const formFields = formObject.donationFormFields || [];
  const localeField = getLocaleField(formFields)!;
  const donationFormField = localeField;

  const punctualAmounts = decodeURI(router.query.punctualAmounts as string);
  const monthlyAmounts = decodeURI(router.query.monthlyAmounts as string);
  const defaultPonctualAmountInputs = useMemo(() => {
    if (punctualAmounts === "undefined") {
      if (donationFormField?.donationFormAmounts?.length) {
        return donationFormField?.donationFormAmounts?.filter((field) => !field.isRecurrent);
      }
      return isPreview
        ? ([2500, 5000, 7500, 10000].map((amount, index) => ({
            amount,
            isRecurrent: false,
            recurrenceInterval: ProductDonationRecurrenceInterval.OneTime,
            id: v4(),
            sortIndex: index,
          })) as unknown as DonationFormAmountObject[])
        : [];
    }
    const parsedPunctualAmounts = JSON.parse(punctualAmounts) as string[];
    return (
      donationFormField?.donationFormAmounts
        ?.filter((field) => !field.isRecurrent)
        .map((field, index) => {
          if (parsedPunctualAmounts[index] && !isNaN(parseInt(parsedPunctualAmounts[index] as string))) {
            return { ...field, amount: parseInt(parsedPunctualAmounts[index] as string) * 100 };
          }
          return field;
        }) || []
    );
  }, [donationFormField?.donationFormAmounts, isPreview, punctualAmounts]);

  const defaultMonthlyAmountInputs = useMemo(() => {
    if (monthlyAmounts === "undefined") {
      if (donationFormField?.donationFormAmounts?.length) {
        return donationFormField?.donationFormAmounts?.filter((field) => field.isRecurrent);
      }
      return isPreview
        ? ([1000, 2500, 3500, 4500].map((amount, index) => ({
            amount,
            isRecurrent: true,
            recurrenceInterval: ProductDonationRecurrenceInterval.Monthly,
            id: v4(),
            sortIndex: index,
          })) as unknown as DonationFormAmountObject[])
        : [];
    }
    const parsedMonthlyAmounts = JSON.parse(monthlyAmounts) as string[];
    return (
      donationFormField?.donationFormAmounts
        ?.filter((field) => field.isRecurrent)
        .map((field, index) => {
          if (parsedMonthlyAmounts[index] && !isNaN(parseInt(parsedMonthlyAmounts[index] as string))) {
            return { ...field, amount: parseInt(parsedMonthlyAmounts[index] as string) * 100 };
          }
          return field;
        }) || []
    );
  }, [donationFormField?.donationFormAmounts, isPreview, monthlyAmounts]);

  const suggestedAmounts = defaultPonctualAmountInputs.concat(defaultMonthlyAmountInputs);
  const hasSuggestedAmountsPerType = {
    oneTime: suggestedAmounts.some(
      (amount) =>
        (amount.recurrenceInterval as ProductDonationRecurrenceInterval) === ProductDonationRecurrenceInterval.OneTime
    ),
    monthly: suggestedAmounts.some(
      (amount) =>
        (amount.recurrenceInterval as ProductDonationRecurrenceInterval) === ProductDonationRecurrenceInterval.Monthly
    ),
    yearly: suggestedAmounts.some(
      (amount) =>
        (amount.recurrenceInterval as ProductDonationRecurrenceInterval) === ProductDonationRecurrenceInterval.Yearly
    ),
  };

  const hasSingleRecurrenceType = Object.values(hasSuggestedAmountsPerType).filter(Boolean).length === 1;
  const amountRecurrenceInterval = {
    onlyOneType: hasSingleRecurrenceType,
    onlyMonthly: hasSingleRecurrenceType && hasSuggestedAmountsPerType.monthly,
    onlyYearly: hasSingleRecurrenceType && hasSuggestedAmountsPerType.yearly,
  };

  const defaultRecurrenceInterval = getDefaultRecurrenceInterval(hasSuggestedAmountsPerType);
  const { setStripeRecurrenceInterval } = useContext(FrontendFormContext);

  useEffect(() => {
    if (defaultRecurrenceInterval) {
      setStripeRecurrenceInterval(defaultRecurrenceInterval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- We only want to run this once
  }, []);

  const form = useForm<DonationPaymentInput>({
    defaultValues: {
      amount: 0,
      selectedAmountId: undefined,
      recurrenceInterval: defaultRecurrenceInterval,
      answers: initAnswers(formObject.questions || []),
    },
    mode: validationMode,
  });

  const resetForm = useCallback(() => {
    form.reset({});
  }, [form]);

  const description = localeField?.sanitizedDescription || localeField?.description || undefined;

  const hasBanner = Boolean(formObject.bannerUrl || formObject.bannerVideoUrl);

  const formOrganization = formObject.organization!;

  const amount = useWatch({
    control: form.control,
    name: "amount",
  });

  const contextValue: DonationFormV2Context = {
    type: FormType.DonationForm,
    formObject,
    form,
    currentStep,
    setCurrentStep,
    setValidationMode,
    resetForm,
    hasBanner,
    formFields,
    description,
    localeField,
    isMembership: false, // Donation form can not be a membership
    isAuction: false, // Donation form can not be an auction
    formCategory: formObject.category,
    suggestedAmounts,
    hasSuggestedAmountsPerType,
    amountRecurrenceInterval,
    questions: formObject.questions || [],
    formOrganization: formOrganization as OrganizationObject,
    formCountry: formOrganization.country || OrganizationCountry.UnitedStates,
    formCurrency: getCurrencyFromCountry(formOrganization?.country || OrganizationCountry.UnitedStates),
    displayGenerateReceipt: Boolean(formOrganization.canGenerateReceipt) && formObject.hasReceipt,
    eligibleAmountTotal: amount,
    ticketsPurchased: undefined,
  };

  return (
    <FormProvider {...form}>
      <FormV2Context.Provider value={contextValue}>
        {/*  Only call useDonationFormUpdated if the form is not in preview mode to optimize performance of Live Editor*/}
        {!isPreview && <DonationFormUpdatedHook control={form.control} />}

        {children}
      </FormV2Context.Provider>
    </FormProvider>
  );
};

const initAnswers = (questions: QuestionOutput[]) => {
  return questions.map((question) => ({
    questionId: question.id,
    id: v4(),
    answers: [],
  }));
};

const DonationFormUpdatedHook: React.FC<{ control: Control<DonationPaymentInput> }> = ({ control }) => {
  useDonationFormUpdated({ control: control as unknown as Control<DonationFormPaymentInput> });
  return null;
};
