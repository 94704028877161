/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FetchResult } from "@apollo/client";
import { RecaptchaGuardMessage, RECAPTCHA_HEADERS } from "@simplyk/common";

import { isTest } from "../constants/env";
import { RecaptchaAction } from "../enums/recaptcha";
import { captureSentryMessage } from "../helpers/sentry";

const RetryAllowed = 2;

interface GrecaptchaEnterprise {
  ready(callback: () => void): void;
  execute(siteKey: string, options?: { action?: string }): Promise<string>;
}

export const getGoogleRecaptcha = (): GrecaptchaEnterprise | undefined => {
  // type-coverage:ignore-next-line
  return (window as any).grecaptcha?.enterprise;
};

export const useRecaptchaRetry = (onRecaptchaScriptNotLoaded?: () => void) => {
  const getRecaptchaToken = async (
    action: RecaptchaAction,
    browserErrorEmail: string | undefined | null,
    retryCount = 0
  ): Promise<string | null | undefined> => {
    try {
      if (isTest) {
        return "test";
      }

      const grecaptcha = getGoogleRecaptcha();

      if (!grecaptcha || !grecaptcha.ready) {
        captureSentryMessage({
          message: "Recaptcha script not loaded",
          params: {
            browserErrorEmail: browserErrorEmail || "undefined",
            recaptchaIsPresentInWindow: (!!grecaptcha).toString(),
            recaptchaReadyIsPresentInWindow: (!!grecaptcha?.ready).toString(),
          },
        });

        if (onRecaptchaScriptNotLoaded) {
          onRecaptchaScriptNotLoaded();
        }

        return undefined;
      }

      return await new Promise<string>((resolve, reject) => {
        grecaptcha.ready(() => {
          grecaptcha
            .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY!, { action })
            .then((token: string | null) => {
              if (!token) {
                reject(new Error("Not able to fetch recaptcha token"));
              } else {
                resolve(token);
              }
            })
            .catch((error: unknown) => {
              reject(error);
            });
        });
      });
    } catch (error) {
      if (retryCount < RetryAllowed) {
        return getRecaptchaToken(action, browserErrorEmail, retryCount + 1);
      }
      captureSentryMessage({
        message: "Not able to fetch recaptcha token after retries",
        params: { browserErrorEmail: browserErrorEmail || "undefined", error: JSON.stringify(error) },
      });
      return null;
    }
  };

  const execute = async <T, K>(
    action: RecaptchaAction,
    browserErrorEmail: string | undefined | null,
    request: (options: K) => Promise<FetchResult<T>>,
    options: K & { context?: { headers?: Record<string, string> } },
    retryCount: number = 0,
    onRecaptchaScriptNotLoaded?: () => void
  ): Promise<FetchResult<T>> => {
    const recaptchaScriptStartTime = Date.now();
    const recaptchaToken = await getRecaptchaToken(action, browserErrorEmail, retryCount);
    const recaptchaScriptEndTime = Date.now();

    const response = await request({
      ...options,
      context: {
        ...options.context,
        headers: {
          ...options?.context?.headers,
          recaptchaToken,
          recaptchaMissingScript: !getGoogleRecaptcha()?.execute,
          recaptchaScriptStartTime,
          recaptchaScriptEndTime,
          recaptchaRetryCount: retryCount.toString(),
          browserErrorEmail,
        },
      },
    });

    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (response.errors?.some((error) => error.message === RecaptchaGuardMessage)) {
      if (retryCount < RetryAllowed) {
        const bypassVerificationEmail = "browser_error_success@mailinator.com";
        const newBrowserErrorEmail = browserErrorEmail === bypassVerificationEmail ? undefined : browserErrorEmail;
        return execute(action, newBrowserErrorEmail, request, options, retryCount + 1, onRecaptchaScriptNotLoaded);
      }
      return response;
    }

    return response;
  };

  const executeTrpc = async <T, K>(
    action: RecaptchaAction,
    browserErrorEmail: string | undefined | null,
    request: (args: K) => T & { error?: { code?: string; message?: string } },
    args: K,
    retryCount: number = 0,
    onRecaptchaScriptNotLoaded?: () => void
  ): Promise<T> => {
    const recaptchaScriptStartTime = Date.now();
    const recaptchaToken = await getRecaptchaToken(action, browserErrorEmail, retryCount);
    const recaptchaScriptEndTime = Date.now();
    const recaptchaHeaders = {
      [RECAPTCHA_HEADERS.TOKEN]: recaptchaToken || "",
      [RECAPTCHA_HEADERS.MISSING_SCRIPT]: (!getGoogleRecaptcha()?.execute).toString(),
      [RECAPTCHA_HEADERS.SCRIPT_START_TIME]: recaptchaScriptStartTime.toString(),
      [RECAPTCHA_HEADERS.SCRIPT_END_TIME]: recaptchaScriptEndTime.toString(),
      [RECAPTCHA_HEADERS.RETRY_COUNT]: retryCount.toString(),
      [RECAPTCHA_HEADERS.BROWSER_ERROR_EMAIL]: browserErrorEmail || "",
    };

    try {
      const res = await request({
        ...args,
        recaptchaHeaders,
      });

      const error = res.error;
      if (!error) {
        return res;
      }

      const message = error.message;
      if (message === RecaptchaGuardMessage && retryCount < RetryAllowed) {
        const bypassVerificationEmail = "browser_error_success@mailinator.com";
        const newBrowserErrorEmail = browserErrorEmail === bypassVerificationEmail ? undefined : browserErrorEmail;
        return executeTrpc(action, newBrowserErrorEmail, request, args, retryCount + 1, onRecaptchaScriptNotLoaded);
      }
      throw error;
    } catch (error: unknown) {
      const castedError = error as { code: string; message: string };
      const message = castedError.message;
      if (message === RecaptchaGuardMessage && retryCount < RetryAllowed) {
        const bypassVerificationEmail = "browser_error_success@mailinator.com";
        const newBrowserErrorEmail = browserErrorEmail === bypassVerificationEmail ? undefined : browserErrorEmail;
        return executeTrpc(action, newBrowserErrorEmail, request, args, retryCount + 1, onRecaptchaScriptNotLoaded);
      }
      throw error;
    }
  };

  return { execute, executeTrpc };
};
