import { Box, Stack, useTheme } from "@mui/material";

import { Button } from "../../../../../components/design-system/Button";
import { Typography } from "../../../../../components/design-system/Typography";

import { ArrowDiagonalRight } from "@/icons/outlined";

interface PeerToPeerDonateOrFundraiseOptionProps {
  title: string;
  description: string;
  buttonText: string;
  buttonVariant: "filled" | "outlined";
  href?: string;
  onClick?: () => void;
  "data-test"?: string;
}

export const PeerToPeerDonateOrFundraiseOption = ({
  title,
  description,
  buttonText,
  buttonVariant,
  href,
  onClick,
  "data-test": dataTest,
}: PeerToPeerDonateOrFundraiseOptionProps) => {
  const theme = useTheme();

  return (
    <Stack
      direction={{ sm: "row" }}
      gap={{ sm: 1 }}
      alignItems={{ xs: "flex-start", sm: "center" }}
      width="100%"
      padding={1.5}
      sx={{
        backgroundColor: theme.palette.surface.form.quiet,
        borderRadius: theme.spacing(1),
      }}
    >
      <Box flexGrow={1}>
        <Typography variant="subtitle1" vibe="text-form-supershy">
          {title}
        </Typography>
        <Typography variant="body2" vibe="text-form-supershy">
          {description}
        </Typography>
      </Box>
      <Button
        variant={buttonVariant}
        vibe="brand"
        size="medium"
        endIcon={
          <ArrowDiagonalRight fill={buttonVariant === "outlined" ? theme.palette.text.form.supershy : undefined} />
        }
        sx={{
          marginTop: {
            xs: theme.spacing(1),
            sm: 0,
          },
          color: buttonVariant === "outlined" ? `${theme.palette.text.form.supershy} !important` : undefined,
          ["&:hover"]: {
            backgroundColor:
              buttonVariant === "outlined" ? `${theme.palette.surface.brand.moderate} !important` : undefined,
          },
        }}
        href={href}
        onClick={onClick}
        data-test={dataTest}
      >
        {buttonText}
      </Button>
    </Stack>
  );
};
